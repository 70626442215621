import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { colors } from "../../styles/colors";
import HorizontalDivider from "./HorizontalDivider";

const TranscriptsDetails = (props) => {
  const { data } = props;

  const [transcript, setTranscript] = React.useState<any>([]);

  React.useEffect(() => {
    if (data && data?.transcripts) {
      setTranscript(JSON.parse(data?.transcripts));
    }
  }, [data]);

  return (
    <Box sx={{ backgroundColor: colors.secondary, p: 4 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="15px" fontWeight={700}>
            TRANSCRIPT DETAILS
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <HorizontalDivider />
        </Grid>
        <Grid
          item
          lg={10}
          md={10}
          sm={8}
          xs={12}
          display={{ xs: "none", sm: "block" }}
        />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="12px" color="#474747" fontWeight={600}>
            College Name:{" "}
            <span style={{ fontWeight: 400, fontSize: "11px" }}>
              {data?.college || ""}
            </span>
          </Typography>
          <Typography fontSize="12px" color="#474747" fontWeight={600}>
            Degree:{" "}
            <span style={{ fontWeight: 400, fontSize: "11px" }}>
              {data?.course?.name || ""}
            </span>
          </Typography>
          <Typography fontSize="12px" color="#474747" fontWeight={600}>
            Department:{" "}
            <span style={{ fontWeight: 400, fontSize: "11px" }}>
              {data?.specialization || ""}
            </span>
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TableContainer>
            <Table
              aria-label="simple table"
              sx={{ border: `1px solid #E3E3E3`, bordeRadius: "4px" }}
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell
                    sx={{ borderRight: `1px solid #E3E3E3` }}
                    align="center"
                  >
                    <Typography
                      color={colors.fontLight}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      Semester
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: `1px solid #E3E3E3` }}
                    align="center"
                  >
                    <Typography
                      color={colors.fontLight}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      Subject Name
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: `1px solid #E3E3E3` }}
                    align="center"
                  >
                    <Typography
                      color={colors.fontLight}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      Credit Hours
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: `1px solid #E3E3E3` }}
                    align="center"
                  >
                    <Typography
                      color={colors.fontLight}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      Grade/ Percentage
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: `1px solid #E3E3E3` }}
                    align="center"
                  >
                    <Typography
                      color={colors.fontLight}
                      fontSize="12px"
                      fontWeight={600}
                    >
                      SGPA/ Percentage
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transcript &&
                  transcript?.semester_details &&
                  transcript?.semester_details.map((sem, index) => (
                    <>
                      <TableRow>
                        <TableCell
                          rowSpan={sem?.subjects?.length || 1}
                          sx={{ borderRight: `1px solid #E3E3E3` }}
                        >
                          <Typography color={colors.fontLight} fontSize="10px">
                            Semester {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderRight: `1px solid #E3E3E3` }}>
                          <Typography color={colors.fontLight} fontSize="10px">
                            {sem?.subjects && sem?.subjects[0].name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderRight: `1px solid #E3E3E3` }}>
                          <Typography color={colors.fontLight} fontSize="10px">
                            {sem?.subjects && sem?.subjects[0].credits}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderRight: `1px solid #E3E3E3` }}>
                          <Typography color={colors.fontLight} fontSize="10px">
                            {sem?.subjects && sem?.subjects[0].cgpa}
                          </Typography>
                        </TableCell>
                        <TableCell
                          rowSpan={sem?.subjects?.length || 1}
                          sx={{ borderRight: `1px solid #E3E3E3` }}
                        >
                          <Typography color={colors.fontLight} fontSize="10px">
                            {sem?.sgpa || "-"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {sem?.subjects &&
                        sem?.subjects.length > 1 &&
                        sem?.subjects.map(
                          (sub, index) =>
                            index !== 0 && (
                              <TableRow>
                                <TableCell
                                  sx={{ borderRight: `1px solid #E3E3E3` }}
                                >
                                  <Typography
                                    color={colors.fontLight}
                                    fontSize="10px"
                                  >
                                    {sub?.name || "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderRight: `1px solid #E3E3E3` }}
                                >
                                  <Typography
                                    color={colors.fontLight}
                                    fontSize="10px"
                                  >
                                    {sub?.credits || "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ borderRight: `1px solid #E3E3E3` }}
                                >
                                  <Typography
                                    color={colors.fontLight}
                                    fontSize="10px"
                                  >
                                    {sub?.cgpa || "-"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                        )}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="flex-end"
        >
          <Typography fontSize="12px" fontWeight={600}>
            Total CGPA: {transcript?.cgpaAllSem}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TranscriptsDetails;
