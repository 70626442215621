/**
 * Asynchronously loads the component for StudentProfile
 */

import { lazyLoad } from "utils/loadable";

export const StudentProfile = lazyLoad(
  () => import("./index"),
  (module) => module.StudentProfile
);
