/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from "utils/loadable";

export const NoInternetPage = lazyLoad(
  () => import("./index"),
  (module) => module.NoInternetPage
);
