/**
 * Asynchronously loads the component for EmployerCollegeList
 */

 import { lazyLoad } from "utils/loadable";

 export const EmployerCollegeList = lazyLoad(
   () => import("./index"),
   (module) => module.EmployerCollegeList
 );
 