/**
 * Asynchronously loads the component for StudentFavourites
 */

import { lazyLoad } from "utils/loadable";

export const StudentFavourites = lazyLoad(
  () => import("./index"),
  (module) => module.StudentFavourites
);
