/**
 * Asynchronously loads the component for CampusServiceOfferings
 */

import { lazyLoad } from "utils/loadable";

export const CampusServiceOfferings = lazyLoad(
  () => import("./index"),
  (module) => module.CampusServiceOfferings
);
