const images = {
  login_image: require("./login-image.svg").default,
  login_landing_page_image: require("./login_landing_page_image.png"),
  student_registration_landing_page_image: require("./student_registration_landing_page_image.png"),
  college_registration_landing_page_image: require("./college_registration_landing_page_image.png"),
  company_registration_landing_page_image: require("./company_registration_landing_page_image.png"),
  company: require("./company.svg").default,
  college: require("./college.svg").default,
  circle_tick_green: require("./circle_tick_green.svg").default,
  circle_tick_grey: require("./circle_tick_grey.svg").default,
  college_student: require("./college_student.svg").default,
  fresher: require("./fresher.svg").default,
  working_professional: require("./working_professional.svg").default,
  home_icon_grey: require("./home_icon_grey.svg").default,
  home_icon_blue: require("./home_icon_blue.svg").default,
  suitcase_icon_grey: require("./suitcase_icon_grey.svg").default,
  suitcase_icon_blue: require("./suitcase_icon_blue.svg").default,
  suitcase_icon2_blue: require("./suitcase_icon2_blue.svg").default,
  upskilling_icon_grey: require("./upskilling_icon_grey.svg").default,
  upskilling_icon_blue: require("./upskilling_icon_blue.svg").default,
  score_icon_grey: require("./score_icon_grey.svg").default,
  score_icon_blue: require("./score_icon_blue.svg").default,
  date_icon_blue: require("./date_icon_blue.svg").default,
  date_icon_grey: require("./date_icon_grey.svg").default,
  application_icon_blue: require("./application_icon_blue.svg").default,
  application_icon_grey: require("./application_icon_grey.svg").default,
  student_home_icon: require("./student_home_icon.svg").default,
  student_suitcase_icon: require("./student_suitcase_icon.svg").default,
  student_upskill_icon: require("./student_upskill_icon.svg").default,
  student_score_icon: require("./student_score_icon.svg").default,
  student_date_icon: require("./student_date_icon.svg").default,
  student_file_icon: require("./student_file_icon.svg").default,
  student_statistics_image: require("./student_statistics_image.png"),
  student_applications_icon: require("./student_applications_icon.svg").default,
  student_profile_views_icon: require("./student_profile_views_icon.svg")
    .default,
  student_learning_hours_icon: require("./student_learning_hours_icon.svg")
    .default,
  student_events_icon: require("./student_events_icon.svg").default,
  student_build_resume_icon: require("./student_build_resume_icon.svg").default,
  question_circled: require("./question_circled.svg").default,
  student_view_assessments: require("./student_view_assessments.svg").default,
  student_company_logo: require("./student_company_logo.png"),
  student_course_banner: require("./student_course_banner.png"),
  student_course_banner2: require("./student_course_banner2.png"),
  student_course_banner3: require("./student_course_banner3.png"),
  student_jobs_internships_icon1:
    require("./student_jobs_internships_icon1.svg").default,
  student_jobs_internships_icon2:
    require("./student_jobs_internships_icon2.svg").default,
  student_jobs_internships_icon3:
    require("./student_jobs_internships_icon3.svg").default,
  student_events_banner: require("./student_events_banner.png"),
  student_events_banner2: require("./student_events_banner2.png"),
  student_events_banner3: require("./student_events_banner3.png"),
  share_icon_blue: require("./share_icon_blue.svg").default,
  save_icon_blue: require("./save_icon_blue.svg").default,
  saved_icon_blue: require("./saved_icon_blue.svg").default,
  student_location_icon_blue: require("./student_location_icon_blue.svg")
    .default,
  student_positions_icon_blue: require("./student_positions_icon_blue.svg")
    .default,
  student_experience_icon_blue: require("./student_experience_icon_blue.svg")
    .default,
  student_rupee_icon_blue: require("./student_rupee_icon_blue.svg").default,
  student_education_icon_blue: require("./student_education_icon_blue.svg")
    .default,
  student_news_feed_image: require("./student_news_feed_image.png"),
  student_right_click_icon: require("./student_right_click_icon.svg").default,
  student_left_click_icon: require("./student_left_click_icon.svg").default,
  x_icon: require("./x_icon.svg").default,
  facebook_icon: require("./facebook_icon.svg").default,
  instagram_icon: require("./instagram_icon.svg").default,
  linkedin_icon: require("./linkedin_icon.svg").default,
  mobile_nav_courses_icon: require("./mobile_nav_courses_icon.svg").default,
  mobile_nav_mafoi_score_icon: require("./mobile_nav_mafoi_score_icon.svg")
    .default,
  // homepage_carousel_1: require("./homepage-carousel1.svg").default,
  // homepage_carousel_2: require("./homepage-carousel2.svg").default,
  // homepage_carousel_3: require("./homepage-carousel3.svg").default,
  otp_verification: require("./otp-verification.svg").default,
  circle_tick: require("./circle_tick.svg").default,
  logout_icon_red: require("./logout_icon_red.svg").default,
  logout_icon: require("./logout_icon.svg").default,
  profile_icon_grey: require("./profile_icon_grey.svg").default,
  student_resume_icon: require("./student_resume_icon.svg").default,
  work_experience_icon_grey: require("./work_experience_icon_grey.svg").default,
  security_icon_grey: require("./security_icon_grey.svg").default,
  profile_icon_blue: require("./profile_icon_blue.svg").default,
  work_experience_icon_blue: require("./work_experience_icon_blue.svg").default,
  security_icon_blue: require("./security_icon_blue.svg").default,
  user_icon_blue: require("./user_icon_blue.svg").default,
  upload_resume_image: require("./upload_resume_image.svg").default,
  upload_video_resume_image: require("./upload_video_resume_image.svg").default,
  play_video_icon_blue: require("./play_video_icon_blue.svg").default,
  cover_letter_image: require("./cover_letter_image.svg").default,
  resume_template_1: require("./resume_template_1.png"),
  resume_template_2: require("./resume_template_2.png"),
  resume_template_3: require("./resume_template_3.png"),
  tick_icon_white: require("./tick_icon_white.svg").default,
  info_icon_blue: require("./info_icon_blue.svg").default,
  mafoi_score_badge: require("./mafoi_score_badge.png"),
  mafoi_score_badge_white_bg: require("./mafoi_score_badge_white_bg.svg")
    .default,
  mafoi_score_badge_blue_bg: require("./mafoi_score_badge_blue_bg.svg").default,
  link_icon: require("./link_icon.svg").default,
  delete_icon_red: require("./delete_icon_red.png"),
  email_icon_blue: require("./email_icon_blue.svg").default,
  smartphone_icon_blue: require("./smartphone_icon_blue.svg").default,
  linkedin_icon_blue: require("./linkedin_icon_blue.svg").default,
  location_icon_blue: require("./location_icon_blue.svg").default,
  globe_icon_blue: require("./globe_icon_blue.svg").default,
  mafoi_badge_white_0: require("./mafoi_badge_white_0.svg").default,
  question_icon_blue: require("./question_icon_blue.svg").default,
  marks_icon_blue: require("./marks_icon_blue.svg").default,
  hours_icon_blue: require("./hours_icon_blue.svg").default,
  attempts_icon_blue: require("./attempts_icon_blue.svg").default,
  aptitude_banner_image: require("./aptitude_banner_image.png"),
  psychometric_banner_image: require("./psychometric_banner_image.png"),
  academic_banner_image: require("./academic_banner_image.png"),
  aptitude_icon_blue: require("./aptitude_icon_blue.svg").default,
  psychometric_icon_blue: require("./psychometric_icon_blue.svg").default,
  adaptability_icon_blue: require("./adaptability_icon_blue.svg").default,
  convincing_icon_blue: require("./convincing_icon_blue.svg").default,
  decisiveness_icon_blue: require("./decisiveness_icon_blue.svg").default,
  dependability_icon_blue: require("./dependability_icon_blue.svg").default,
  networking_icon_blue: require("./networking_icon_blue.svg").default,
  people_icon_blue: require("./people_icon_blue.svg").default,
  planning_icon_blue: require("./planning_icon_blue.svg").default,
  persistence_icon_blue: require("./persistence_icon_blue.svg").default,
  service_icon_blue: require("./service_icon_blue.svg").default,
  stress_icon_blue: require("./stress_icon_blue.svg").default,
  target_icon_blue: require("./target_icon_blue.svg").default,
  filter_icon_blue: require("./filter_icon_blue.svg").default,
  notification_icon_grey: require("./notification_icon_grey.png"),
  notification_icon_blue: require("./notification_icon_blue.svg").default,
  resume_builder_icon_grey: require("./resume_builder_icon_grey.svg").default,
  resume_builder_icon_blue: require("./resume_builder_icon_blue.svg").default,
  page_not_found: require("./page_not_found.png"),
  forbidden: require("./forbidden.png"),
  launching_soon: require("./launching_soon.png"),
  no_internet: require("./no_internet.png"),
  under_maintenance: require("./under_maintenance.png"),

  //Employer
  employer_applied_candidates_icon_grey:
    require("./employer_applied_candidates_icon_grey.svg").default,
  employer_applied_candidates_icon_blue:
    require("./employer_applied_candidates_icon_blue.svg").default,
  employer_search_candidates_icon_grey:
    require("./employer_search_candidates_icon_grey.svg").default,
  employer_search_candidates_icon_blue:
    require("./employer_search_candidates_icon_blue.svg").default,
  employer_college_connect_icon_grey:
    require("./employer_college_connect_icon_grey.svg").default,
  employer_college_connect_icon_blue:
    require("./employer_college_connect_icon_blue.svg").default,
  company_icon_blue: require("./company_icon_blue.svg").default,
  company_icon_grey: require("./company_icon_grey.svg").default,
  contact_icon_blue: require("./contact_icon_blue.svg").default,
  contact_icon_grey: require("./contact_icon_grey.svg").default,
  applied_icon: require("./applied_icon.svg").default,
  shortlisted_icon: require("./shortlisted_icon.svg").default,
  interviewed_icon: require("./interviewed_icon.svg").default,
  selected_icon: require("./selected_icon.svg").default,
  offered_icon: require("./offered_icon.svg").default,
  joined_icon: require("./joined_icon.svg").default,
  rejected_icon: require("./rejected_icon.svg").default,
  list_icon_grey: require("./list_icon_grey.svg").default,
  list_icon_blue: require("./list_icon_blue.svg").default,
  grid_icon_grey: require("./grid_icon_grey.svg").default,
  grid_icon_blue: require("./grid_icon_blue.svg").default,
  draft_icon_blue: require("./draft_icon_blue.svg").default,
  close_icon_blue: require("./close_icon_blue.svg").default,
  refresh_icon_blue: require("./refresh_icon_blue.svg").default,
  access_privilege_icon_blue: require("./access_privilege_icon_blue.svg")
    .default,
  email_icon2_blue: require("./email_icon2_blue.svg").default,
  email_icon3_blue: require("./email_icon3_blue.svg").default,
  mobile_icon2_blue: require("./mobile_icon2_blue.svg").default,
  location_icon2_blue: require("./location_icon2_blue.svg").default,
  account_icon2_blue: require("./account_icon2_blue.svg").default,
  university_icon_blue: require("./university_icon_blue.svg").default,
  badge_icon_blue: require("./badge_icon_blue.svg").default,
  discount_icon_blue: require("./discount_icon_blue.svg").default,
  edit_icon_blue: require("./edit_icon_blue.svg").default,
  phone_icon_blue: require("./phone_icon_blue.svg").default,
  shortlist_icon_white: require("./shortlist_icon_white.svg").default,
  shortlist_candidate_banner: require("./shortlist_candidate_banner.png"),
  download_icon: require("./download.svg").default,
  internship_icon: require("./internship_icon.svg").default,
  applicants_icon: require("./applicants_icon.svg").default,
  offered_candidates_icon: require("./offered_candidates_icon.svg").default,
  engineering_cc_icon: require("./engineering-outline.svg").default,
  art_science_cc_icon: require("./art-and-science.svg").default,
  polytechnic_cc_icon: require("./polytechnic.svg").default,
  mgmt_studies_cc_icon: require("./management-studies.svg").default,
  add_icon_blue: require("./add_icon_blue.png"),

  // Locations
  chennai: require("./chennai.svg").default,
  mumbai: require("./mumbai.svg").default,
  pune: require("./pune.svg").default,
  kolkata: require("./kolkata.svg").default,
  hyderabad: require("./hyderabad.svg").default,
  bangalore: require("./bangalore.svg").default,
  // Jobs
  marketing: require("./marketing.svg").default,
  design: require("./web-design.svg").default,
  hrd: require("./hrd.svg").default,
  finance: require("./finance-management.svg").default,
  part_time_jobs: require("./part-time.svg").default,
  business_consulation: require("./business-consultation.svg").default,
  customer_support: require("./customer-support.svg").default,
  project_management: require("./project-management.svg").default,
  student_dashboard_hello: require("./student-dashboard-hello.svg").default,
  // Skills
  code: require("./code.svg").default,
  code_2: require("./code-2.svg").default,
  coding: require("./coding.svg").default,
  sql: require("./sql.svg").default,
  creative_writing: require("./creative-writing.svg").default,
  digital_marketing: require("./digital-marketing.svg").default,
  excel: require("./excel.svg").default,
  hacker: require("./hacker.svg").default,
  // handshake: require("./handshake.svg").default,
  machine_learning: require("./machine-learning.svg").default,
  //Feedback
  meeting: require("./meeting.svg").default,
  office: require("./office.svg").default,
  popup: require("./popup.svg").default,
  python: require("./python.svg").default,
  quotes: require("./quotes.svg").default,
  quotes2: require("./quotes2.svg").default,
  //Recomended
  devops: require("./devops.svg").default,
  web_dev: require("./web-dev.svg").default,
  data_analyst: require("./data-analyst.svg").default,
  product_manager: require("./product-manager.svg").default,
  fe_developer: require("./fe-developer.svg").default,
  ai_engineer: require("./ai-developer.svg").default,
  //no results
  no_result: require("./no-results-found.svg").default,
  no_saved_results: require("./no-saved-results.svg").default,
  //icon
  share: require("./share-icon.svg").default,
  like: require("./like.svg").default,
  job_image: require("./job_image.svg").default,
  Testimonal: require("./Testimonal.png"),
  separator: require("./separator.svg").default,
  remove_icon: require("./remove-icon.svg").default,
  // footer icons
  twitter: require("./twitter.svg").default,
  youtube: require("./youtube.svg").default,
  instagram: require("./instagram.svg").default,
  facebook: require("./facebook.svg").default,
  cielLogo: require("./cielLogo.svg").default,
  mafoiLogo: require("./mafoi.svg").default,
  IntegrumLogo: require("./Integrum.svg").default,
  companyC: require("./companyCo.svg").default,
  line: require("./line.svg").default,
  intership_image: require("./internship_image.svg").default,
  pdf_icon: require("./pdf-icon.svg").default,
  mp4_icon: require("./mp4-icon.svg").default,
  // Student flow
  successTick: require("./SuccessTick.svg").default,
  // campus flow
  campusBannerLogo: require("./campusBannerLogo.svg").default,
  campusGraph: require("./campusGraph.svg").default,
  companyIconK: require("./companyIconK.svg").default,
  NoIndustry: require("./NoIndustry.svg").default,
  pdfImage: require("./pdfImage.svg").default,
  // community
  shareIcon: require("./shareIcon.svg").default,
  commentIcon: require("./commentIcon.svg").default,
  //mock images temporary
  discussion1mock: require("./discussion1mock.svg").default,
  discussion2mock: require("./discussion2mock.svg").default,
  //Employer Internship
  personApplicants: require("./person-Application.svg").default,
  employer_dashboard_hello: require("./employer-dashboard-hello.svg").default,
  //Modals images
  alert: require("./alert.svg").default,
  empty: require("./emptyState.svg").default,
  logout: require("./logout.svg").default,
  logout_icon_blue: require("./logout_icon_blue.svg").default,
  empty_dashboard_campus: require("./empty-dashboard-campus.svg").default,
  prosculpt_logo_colored: require("./prosculpt_logo_colored.png"),
  prosculpt_logo_white: require("./prosculpt-logo-white.svg").default,
  landing_page_student: require("./landing-page-student.svg").default,
  landing_page_employers: require("./landing-page-employers.svg").default,
  landing_page_campus: require("./landing-page-campus.svg").default,
  bites_prosculpt_logo: require("./bites-prosculpt-logo.svg").default,
  twitterB: require("./twitterB.svg").default,
  youtubeB: require("./youtubeB.svg").default,
  instagramB: require("./instagramB.svg").default,
  facebookB: require("./facebookB.svg").default,
  linkedinB: require("./linkedinB.svg").default,

  // Resume
  reOval: require("./reOval.svg").default,
  female: require("./Female.svg").default,
  // Photo: require("./Photo.svg").default,
  remail: require("./remail.svg").default,
  relocatiom: require("./relocation.svg").default,
  rephone: require("./rephone.svg").default,
  pdf: require("./pdf.svg").default,
  no_jobs_view: require("./no-jobs-view.svg").default,
  access_limit: require("./access-limit.png"),

  underline_homepage: require("./underline-homepage.svg").default,
  backgroung_homepage: require("./homepage-background.svg").default,
  employers_homepage: require("./employers-homepage.svg").default,
  campus_homepage: require("./campus-homepage.svg").default,
  students_homepage: require("./students-homepage.svg").default,
  bullet_homepage: require("./bullet-homepage.svg").default,
  college1: require("./college1.svg").default,
  college2: require("./college2.svg").default,
  college3: require("./college3.svg").default,
  college4: require("./college4.png"),
  college5: require("./college5.svg").default,
  college6: require("./college6.svg").default,
  college7: require("./college7.svg").default,
  college8: require("./college8.svg").default,
  college9: require("./college9.png"),
  college10: require("./college10.svg").default,
  college11: require("./college11.svg").default,
  college12: require("./college12.svg").default,
  college13: require("./college13.svg").default,
  college14: require("./college14.svg").default,
  testimonial1: require("./testimonial1.svg").default,
  testimonial2: require("./testimonial2.svg").default,
  testimonial3: require("./testimonial3.svg").default,
  testimonial4: require("./testimonial4.svg").default,
  testimonial5: require("./testimonial5.png"),
  linkedin1: require("./linkedin1.svg").default,
  facebook1: require("./facebook1.svg").default,
  instagram1: require("./instagram1.svg").default,
  twitter1: require("./twitter1.svg").default,
  mafoiscorecard1: require("./ScoreCard.svg").default,
  chart1: require("./Chart.svg").default,
  mafoiscorebadgewhite: require("./mafoi_scorebadge_white.svg").default,
  mafoiscorebadgeblue: require("./mafoi_score_badge_blue.svg").default,
  attachment_clip: require("./attachment_clip.svg").default,
  attachment_clip_white: require("./attachment_clip_white.svg").default
};

export default images;
