export const colors = {
  primaryBlue: "#2667B0",
  primaryBlueDark: "#225b9c",
  primaryBlueLight: "#2667B04D",
  successGreen: "#28C76F",
  lightGreyBorder: "#D0CDCD",
  primary: "#1B7EB3",
  primaryLight: "#F4F7FC",
  secondary: "#FFFFFF",
  success: "#11998E",
  warning: "#F09819",
  danger: "#FF5E62",
  fontPrimary: "#000000",
  fontGrey: "#494949",
  fontDarkBlue: "#102B4A",
  fontLight: "#555555",
  fontLighter: "rgba(85, 85, 85, 0.5)",
  fontLighter2: "#646262",
  lightGreen: "#69B21B",
  lightGray: "#585858",
  lightGreyCircle: "#CECFCF",
  primaryFill: "rgba(27, 126, 179, 0.09)",
  successFill: "rgba(17, 153, 142, 0.15)",
  warningFill: "rgba(240, 152, 25, 0.15)",
  dangerFill: "rgba(255, 94, 98, 0.15)",
  chartTitle: "#464255",
  blueBackGround: "#E2EEF5",
  lightBlueBackground: "#F4FAFF",
  borderColor: "#E3E3E3",
  lightBgColor: "#F7F7F7",
  sucessGreen: "#20BC87",
  iconColor: "#949494",
  gray: "#444444",
  lighterGray: "#A4A5A8",
  lighterGray2: "#D4D4D8",
  lighterGray3: "#d4d4d4bf",
  strokeGray: "#F5F5F5",
  logoutRed: "#CF0915",
  lightBlueChip: "#E9F0F7BF",
  lightBlueBadge: "#3497D933",
  lightGrey: "#494949BF",
  resume_template_color2: "#00BCA3",
  resume_template_color3: "#102A73",
  resume_template_color4: "#F6911E",
  resume_template_color5: "#CB454E",
  resume_template_color6: "#28C76F",
  resume_template_color7: "#FACD05",
  resume_template_color8: "#2B98DE",
  resume_template_color9: "#FED78C",
  grey: "#B3BFCC",
  resume_skills_chip_background: "#E9F0F7",
  orange: "#EDB41E",
  red: "#FE0000",
  lightYellow: "#FED78C",

  chipGreen: "#50C878",
  chipBlue: "#008DE7",
  chipYellow: "#FFA500",
  chipTealGreen: "#008080",
  chipOrange: "#FF7F50",
  chipViolet: "#6158BD",
  chipRed: "#DC3545",
  tableHeaderBackground: "#F2F2F2",

  //grid view colors
  appliedBg: "#007BFF1A",
  shortlistedBg: "#FFC1071A",
  interviewedBg: "#FE6A331A",
  selectedBg: "#007BFF1A",
  offeredBg: "#007BFF1A",
  joinedBg: "#007BFF1A",
  rejectedbg: "#DC35451A"
};
