import * as React from "react";
import { Grid, Typography, Chip } from "@mui/material";
import { colors } from "../../../styles/colors";
import { getDateInFormat } from "../../../utils/helpers";

interface QualificationDetailsProps {
  job: any;
}

const QualificationDetails = (props: QualificationDetailsProps) => {
  return (
    <Grid container spacing={1} sx={{ my: 3 }}>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 1 }}>
        <Typography fontSize="16px" fontWeight={500}>
          Qualification Details
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Requested Certificates
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props &&
            props.job &&
            props.job.job &&
            props.job.job.certificates &&
            JSON.parse(props?.job?.job?.certificates).toString()}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Qualification
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props?.job?.job?.qualification?.name}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Year of Passing
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props?.job?.job?.minimumYearOfPassing &&
          props?.job?.job?.maximumYearOfPassing
            ? `${getDateInFormat(
                props?.job?.job?.minimumYearOfPassing,
                "YYYY"
              )} - ${getDateInFormat(
                props?.job?.job?.maximumYearOfPassing,
                "YYYY"
              )}`
            : ""}
        </Typography>
      </Grid>
      <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ mt: 1.5 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px" color={colors.lightGrey}>
            Key Skills
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} mb={1}>
        {props &&
          props.job &&
          props.job.job &&
          props.job.job.skills &&
          JSON.parse(props?.job?.job?.skills).map((skill) => (
            <Grid key={skill} item>
              <Chip
                label={skill}
                sx={{
                  backgroundColor: colors.lightBlueChip,
                  color: colors.primaryBlue,
                  borderRadius: "2px"
                }}
              />
            </Grid>
          ))}
      </Grid>
      <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ mt: 1.5 }}>
        <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
          <Typography fontSize="14px" color={colors.lightGrey}>
            Languages Known
          </Typography>
        </Grid>
        <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12}>
          {props &&
            props.job &&
            props.job.job &&
            props.job.job.languages &&
            props?.job?.job?.languages.map((language) => (
              <Grid item container key={language}>
                <Grid
                  item
                  lg={1}
                  md={1}
                  sm={2}
                  xs={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography fontSize="14px">{language.language}</Typography>
                </Grid>
                <Grid item container spacing={2} lg={11} md={11} sm={10} xs={9}>
                  {language?.read && (
                    <Grid item>
                      <Chip
                        label="Read"
                        sx={{
                          backgroundColor: colors.lightBlueChip,
                          color: colors.primaryBlue,
                          borderRadius: "2px"
                        }}
                      />
                    </Grid>
                  )}
                  {language?.write && (
                    <Grid item>
                      <Chip
                        label="Write"
                        sx={{
                          backgroundColor: colors.lightBlueChip,
                          color: colors.primaryBlue,
                          borderRadius: "2px"
                        }}
                      />
                    </Grid>
                  )}
                  {language?.speak && (
                    <Grid item>
                      <Chip
                        label="Speak"
                        sx={{
                          backgroundColor: colors.lightBlueChip,
                          color: colors.primaryBlue,
                          borderRadius: "2px"
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QualificationDetails;
