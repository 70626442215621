/**
 * Asynchronously loads the component for CampusIndustryConsultingProject
 */

 import { lazyLoad } from "utils/loadable";

 export const CampusIndustryConsultingProject = lazyLoad(
   () => import("./index"),
   (module) => module.CampusIndustryConsultingProject
 );
 