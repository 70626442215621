/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from "utils/loadable";

export const UnderMaintenancePage = lazyLoad(
  () => import("./index"),
  (module) => module.UnderMaintenancePage
);
