/**
 * Asynchronously loads the component for Login
 */

import { lazyLoad } from "utils/loadable";

export const CollegeRegistration = lazyLoad(
  () => import("./index"),
  (module) => module.CollegeRegistration
);
