/**
 * Asynchronously loads the component for StudentProfileCompletion
 */

import { lazyLoad } from "utils/loadable";

export const StudentProfileCompletion = lazyLoad(
  () => import("./ProfileCompletion"),
  (module) => module.StudentProfileCompletion
);
