import * as React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import Modal from "./CommonModal";
import { clearSession } from "../../utils/helpers";
import images from "../../assets/images/images";
import { colors } from "../../styles/colors";

const LogoutModal = (props) => {
  const { open, setOpen, width } = props;

  const history = useHistory();
  const handleClick = () => {
    /**
     * Retrieves the base URI for the WordPress application from the environment variables.
     * Redirects the user to the specified URL, effectively logging them out of the application.
     */
    const url = process.env.REACT_APP_WORDPRESS_BASE_URI || "";
    window.location.href = url;
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      width={width ? width : "100%"}
      hideCloseIcon={true}
    >
      <Grid container spacing={2.5}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={images.logout_icon_red} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize={24} fontWeight={600} textAlign="center">
            Logout !
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
          <Typography
            fontSize="16px"
            color={colors.fontLight}
            textAlign="center"
          >
            Are you sure you want to logout ?
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{
              borderRadius: "1px",
              borderColor: colors.fontLighter,
              color: colors.fontLighter,
              mx: 1.5,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: colors.fontLighter,
                border: `1px solid ${colors.fontLighter}`
              }
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{
              borderRadius: "1px",
              backgroundColor: colors.logoutRed,
              mx: 1.5,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: colors.danger
              }
            }}
            onClick={() => {
              const redirectUrl = localStorage.getItem("redirecturl");
              if (redirectUrl) {
                localStorage.removeItem("redirecturl");
              }
              history.push("/log-out");
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LogoutModal;
