/**
 * Asynchronously loads the component for EmployerCollegeStudentList
 */

 import { lazyLoad } from "utils/loadable";

 export const EmployerCollegeStudentList = lazyLoad(
   () => import("./index"),
   (module) => module.EmployerCollegeStudentList
 );
 