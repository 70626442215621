/**
 * Asynchronously loads the `Login` component for the LoginPage.
 * This function uses the `lazyLoad` utility to dynamically import the `Login` component
 * from the `./index` module when it is first accessed.
 */
import { lazyLoad } from "utils/loadable";

export const LogoutPage = lazyLoad(
  () => import("./index"),
  (module) => module.LogoutPage
);
