/**
 * Asynchronously loads the component for AdminInstitutions
 */

import { lazyLoad } from "utils/loadable";

export const AdminInstitutions = lazyLoad(
  () => import("./index"),
  (module) => module.AdminInstitutions
);
