import * as React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Fade,
  Fab,
  Avatar,
  Paper,
  useMediaQuery,
  useScrollTrigger,
  Breadcrumbs,
  Menu,
  MenuItem,
  Button,
  Popover
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CommonFooter from "./CommonFooter";
import images from "../../assets/images/images";
import { colors } from "../../styles/colors";
import theme from "styles/theme";
import {
  clearSession,
  getEmployerBreadcrumbs,
  getEmployerNavbarSelected,
  getUserInfo,
  isJwtTokenValid
} from "utils/helpers";
import EmployerNotifications from "./EmployerNotificationsMenu";
import GridViewIcon from "@mui/icons-material/GridView";
import MobileNavigationDrawer from "./StudentMobileNavigationDrawer";
import LogoutModal from "./LogoutModal";
import { useQuery } from "@apollo/client";
import { GET_PROFILE_PHOTO } from "app/graphql/StudentProfile";
import { getMIMETypeOfFile } from "utils/constants";
import { GET_USER_DETAILS } from "app/graphql/users";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement;
  mainComponent: React.ReactNode;
  clearNotifications?: boolean;
  properties?: any;
}

const drawerWidth = 240;
const navItems = [
  {
    id: 0,
    name: "Dashboard",
    path: "/employer-dashboard",
    image1: images.home_icon_blue,
    image2: images.home_icon_grey
  },
  {
    id: 1,
    name: "Jobs/Internships",
    path: "/employer-search-job",
    image1: images.suitcase_icon_blue,
    image2: images.suitcase_icon_grey
  },
  // { id: 2, name: "Internships", path: "/employer-internship" },
  {
    id: 2,
    name: "Applied Candidates",
    path: "/employer-applied-candidates",
    image1: images.employer_applied_candidates_icon_blue,
    image2: images.employer_applied_candidates_icon_grey
  },
  {
    id: 3,
    name: "Search Candidates",
    path: "/employer-search-candidates",
    image1: images.employer_search_candidates_icon_blue,
    image2: images.employer_search_candidates_icon_grey
  },
  {
    id: 4,
    name: "College Connect",
    path: "/employer-college-connect",
    image1: images.employer_college_connect_icon_blue,
    image2: images.employer_college_connect_icon_grey
  }
];

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const EmployerNavbar = (props: Props) => {
  const { window, mainComponent, clearNotifications, properties } = props;
  const history = useHistory();
  const location = useLocation();
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState<
    { id: number; name: string; path: string; color: string }[]
  >([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  React.useEffect(() => {
    setBreadcrumbs(getEmployerBreadcrumbs(location));
  }, [location]);

  const [logoutModal, setLogoutModal] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleProfileIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { id: userId, email } = getUserInfo();

  const {
    data: userData,
    loading: fetching,
    refetch
  } = useQuery(GET_USER_DETAILS, {
    variables: { id: userId }
  });

  const {
    data: profilePhoto,
    loading: gettingPhoto,
    refetch: getPhoto
  } = useQuery(GET_PROFILE_PHOTO, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const { roles } = isJwtTokenValid();

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <AppBar
          component="nav"
          elevation={0}
          color="secondary"
          sx={{
            mt: -1,
            borderBottom: `1px solid ${colors.lightGreyBorder}`,
            boxShadow: `0 2px 4px rgba(0, 0, 0, 0.1)`
          }}
        >
          <Toolbar>
            <Box display="flex">
              <Typography
                variant="h6"
                component="div"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <img
                  src={images.prosculpt_logo_colored}
                  height={45}
                  alt="logo"
                />
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                px: { xs: 2, sm: 0 },
                py: { xs: 0.75, sm: 0 },
                display: { xs: "flex", md: "none" },
                borderBottom: `1px solid ${colors.lightGreyBorder}`,
                boxShadow: { xs: "0px 2px 4px rgba(0, 0, 0, 0.1)", sm: "none" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  ml: { lg: 10, md: 5, sm: 3 }
                }}
              >
                <img
                  src={images.prosculpt_logo_colored}
                  style={{ width: matchesXs ? "120px" : "110px" }}
                />
              </Box>
              {location?.pathname?.includes("/student-resume-builder/") ? (
                <IconButton
                  color="primary"
                  edge="start"
                  onClick={() => setMobileOpen(true)}
                  sx={{ ml: 4, display: { sm: "flex", md: "none" } }}
                >
                  <GridViewIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  edge="start"
                  onClick={() => setMobileOpen(true)}
                  sx={{ ml: 4, display: { sm: "flex", md: "none" } }}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
              <Box component="nav">
                <MobileNavigationDrawer
                  mobileOpen={mobileOpen}
                  setMobileOpen={setMobileOpen}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                justifyContent: "center",
                flexGrow: 1
              }}
            >
              {navItems &&
                navItems?.map((item, index) => {
                  return (
                    <Box
                      key={item?.id}
                      sx={{
                        mx: 0.5,
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <img
                        src={
                          getEmployerNavbarSelected(location, item)
                            ? item.image1
                            : item.image2
                        }
                        height={20}
                        alt="My SVG"
                        style={{ fill: "#000000", marginTop: "16px" }}
                      />
                      <Typography
                        key={item.id}
                        color={
                          getEmployerNavbarSelected(location, item)
                            ? colors.primaryBlue
                            : `${colors.fontGrey}`
                        }
                        fontSize="16px"
                        sx={{
                          cursor: "pointer",
                          mt: 3.5,
                          ml: 1,
                          mr: 2.5,
                          pb: 1.5,
                          borderBottom: getEmployerNavbarSelected(
                            location,
                            item
                          )
                            ? 2
                            : 0,
                          borderColor: colors.primaryBlue
                        }}
                        onClick={() => {
                          history.push(item.path);
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
            <Box
              alignItems={"center"}
              color={colors.fontGrey}
              sx={{ mt: 1, display: { xs: "none", md: "flex" } }}
            >
              <EmployerNotifications clearNotifications={clearNotifications} />
              <Button onClick={handleProfileIconClick}>
                {profilePhoto?.getProfilePicture !== null ? (
                  <Avatar>
                    <img
                      src={`data:image/${getMIMETypeOfFile(
                        profilePhoto?.getProfilePicture.fileType
                      )};base64,${profilePhoto?.getProfilePicture.file}`}
                      alt="logo"
                      height={70}
                      width={70}
                    />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{
                      cursor: "pointer",
                      color: colors.secondary,
                      backgroundColor: colors.primary,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {userData?.user?.firstName?.length > 0
                      ? userData?.user?.firstName[0]?.toUpperCase()
                      : ""}
                  </Avatar>
                )}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                PaperProps={{
                  sx: {
                    width: "240px"
                  }
                }}
              >
                <Box display="flex" alignItems="center" sx={{ pX: 0.5, py: 1 }}>
                  <Avatar
                    sx={{
                      width: "40px",
                      height: "40px",
                      fontSize: "14px",
                      fontWeight: 600,
                      margin: "10px",
                      backgroundColor: colors.primaryLight,
                      color: colors.primary,
                      border: `1px solid ${colors.primary}`
                    }}
                  >
                    {userData?.user?.firstName?.length > 0
                      ? userData?.user?.firstName[0]?.toUpperCase()
                      : ""}
                  </Avatar>
                  <Box>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      color={colors.primaryBlue}
                      sx={{
                        wordBreak: "break-word"
                      }}
                    >
                      {userData?.user?.firstName
                        ? `${userData?.user?.firstName} ${userData?.user?.lastName}`
                        : ""}
                    </Typography>
                    <Typography
                      fontSize="14px"
                      color={colors.fontLight}
                      sx={{
                        wordBreak: "break-word"
                      }}
                    >
                      {email || ""}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                {roles?.length > 0 && roles[0] !== "sub_user" && (
                  <>
                    <MenuItem
                      sx={{
                        color: colors.lightGreyBorder,
                        mt: 2,
                        mb: 1
                      }}
                      onClick={() => {
                        history.push("/employer-profile");
                      }}
                    >
                      <img src={images.profile_icon_blue} width={20} />
                      <Typography
                        fontSize={16}
                        color={colors.fontPrimary}
                        ml={2}
                      >
                        Profile Settings
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: colors.lightGreyBorder,
                        my: 1
                      }}
                      onClick={() => {
                        history.push("/employer-access-privileges");
                      }}
                    >
                      <img src={images.access_privilege_icon_blue} width={23} />
                      <Typography
                        fontSize={16}
                        color={colors.fontPrimary}
                        ml={1.5}
                      >
                        Access Privileges
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  sx={{
                    color: colors.lightGreyBorder,
                    my: 1,
                    ml: 0.25
                  }}
                  onClick={() => {
                    setLogoutModal(true);
                    handleClose();
                  }}
                >
                  <img src={images.logout_icon_blue} width={17} />
                  <Typography fontSize={16} color={colors.fontPrimary} ml={2}>
                    Logout
                  </Typography>
                </MenuItem>
                {/* <Divider
                      sx={{
                        my: 0,
                        height: "5px",
                        background: colors.primaryBlue
                      }}
                    /> */}
              </Popover>
              <LogoutModal
                open={logoutModal}
                setOpen={setLogoutModal}
                width={"400px"}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ width: "100%", flexGrow: 1 }}>
          <Toolbar id="back-to-top-anchor" />
          {/* <Paper
            elevation={1}
            sx={{ p: 2, pl: matchesMd ? 5 : 15, borderRadius: 0 }}
          >
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
              {breadcrumbs.map((crumb) => (
                <NavLink
                  key={crumb.id}
                  role="button"
                  to={crumb.path}
                  style={{
                    textDecoration: "none",
                    color: crumb.color,
                    fontSize: "16px"
                  }}
                >
                  {crumb.name}
                </NavLink>
              ))}
            </Breadcrumbs>
          </Paper> */}
          <Box
            sx={{
              py: properties?.py || properties?.py === 0 ? properties?.py : 3,
              px:
                properties?.px || properties?.px === 0
                  ? properties?.px
                  : matchesMd
                  ? 5
                  : 15,
              backgroundColor: "#FAFAFB"
            }}
          >
            {mainComponent}
          </Box>
        </Box>
        <CommonFooter />
      </Box>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default EmployerNavbar;
