/**
 * Asynchronously loads the component for StudentSearchJob
 */

import { lazyLoad } from "utils/loadable";

export const StudentSearchJob = lazyLoad(
  () => import("./index"),
  (module) => module.StudentSearchJob
);
