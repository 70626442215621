/**
 * Asynchronously loads the component for StudentMyApplication
 */

import { lazyLoad } from "utils/loadable";

export const StudentMyApplication = lazyLoad(
  () => import("./index"),
  (module) => module.StudentMyApplication
);
