/**
 * Asynchronously loads the component for TermsAndConditions
 */

import { lazyLoad } from "utils/loadable";

export const TermsAndConditions = lazyLoad(
  () => import("./index"),
  (module) => module.TermsAndConditions
);
