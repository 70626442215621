/**
 * Asynchronously loads the component for Upskilling
 */

import { lazyLoad } from "utils/loadable";

export const StudentMESAptitudeDetails = lazyLoad(
  () => import("./index"),
  (module) => module.StudentMESAptitudeDetails
);
