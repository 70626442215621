/**
 * Asynchronously loads the component for EmployerAppliedCandidates
 */

import { lazyLoad } from "utils/loadable";

export const EmployerAppliedCandidates = lazyLoad(
  () => import("./index"),
  (module) => module.EmployerAppliedCandidates
);
