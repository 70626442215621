/**
 * Asynchronously loads the component for StudentResumeTemplates
 */

import { lazyLoad } from "utils/loadable";

export const StudentResumeTemplate = lazyLoad(
  () => import("./index"),
  (module) => module.StudentResumeTemplate
);
