/**
 * Asynchronously loads the component for AddTranscript
 */

import { lazyLoad } from "utils/loadable";

export const AddTranscript = lazyLoad(
  () => import("./AddTranscript"),
  (module) => module.AddTranscript
);
