import React from "react";

const RenderJobDescriptionHTMLComponent = (props) => {
  const html = props?.htmlString;
  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = html;

  const listItems = tempContainer.querySelectorAll("li");

  listItems.forEach((item) => {
    item.style.pageBreakInside = "avoid";
    item.style.fontFamily = "Roboto, Helvetica, Arial, sans-serif";
    item.style.fontSize = "14px";
  });

  const listItems2 = tempContainer.querySelectorAll("p");

  listItems2.forEach((item) => {
    item.style.fontFamily = "Roboto, Helvetica, Arial, sans-serif";
    item.style.fontSize = "14px";
  });

  const updatedHtmlString = tempContainer.innerHTML;

  return (
    <div
      style={{
        fontSize: "14px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
      }}
      dangerouslySetInnerHTML={{ __html: updatedHtmlString }}
    />
  );
};

export default RenderJobDescriptionHTMLComponent;
