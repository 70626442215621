/**
 * Asynchronously loads the component for CampusDashboard
 */

import { lazyLoad } from "utils/loadable";

export const CampusDashboard = lazyLoad(
    () => import("./index"),
    (module) => module.CampusDashboard
);
