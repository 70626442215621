/**
 * Asynchronously loads the component for CampusStudentActivity
 */

import { lazyLoad } from "utils/loadable";

export const CampusStudentActivity = lazyLoad(
  () => import("./index"),
  (module) => module.CampusStudentActivity
);
