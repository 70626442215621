import { gql } from "@apollo/client";

export const ADD_JOB = gql`
  mutation createjob($payload: JobCreateInput!) {
    createJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($payload: JobUpdateInput!) {
    updateJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($payload: UpdateJobStatus!) {
    updateJobStatus(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_JOB = gql`
  query job($id: String!) {
    job(id: $id) {
      id
      title
      jobType
      jobMode
      jobTeamType
      description
      category {
        id
        name
      }
      industry {
        id
        name
      }
      qualification {
        id
        name
      }
      courses
      certificates
      skills
      state {
        id
        name
      }
      city {
        id
        name
      }
      country {
        id
        name
      }
      organization
      createdBy {
        id
        username
        organization {
          id
          name
        }
      }
      location
      positions
      salaryConfidential
      minimumRemuneration
      maximumRemuneration
      perks
      minimumExperience
      maximumExperience
      minimumYearOfPassing
      maximumYearOfPassing
      genderPreference
      closure
      startDate
      duration
      confidential
      profile
      createdBy {
        id
        firstName
        lastName
      }
      status
      numberOfViews
      AppliedCount
      ShortlistedCount
      interviewedCount
      selectedCount
      OfferedCount
      joinedCount
      RejectedCount
      jobStatus
      isSaved
      modifiedDate
      createdDate
      stipendType
      languages {
        id
        language
        read
        write
        speak
      }
      publishTo
      jobCampus {
        id
        campus {
          id
          name
        }
      }
      jobStates {
        id
        state {
          id
          name
        }
      }
      numberOfViews
    }
  }
`;

export const GET_JOBS = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $not_candidate: String
  ) {
    jobs(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      not_candidate: $not_candidate
    ) {
      count
      jobs {
        id
        title
        jobType
        jobMode
        jobTeamType
        description
        category {
          id
          name
        }
        qualification {
          id
          name
        }
        city {
          id
          name
        }
        skills
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        numberOfViews
        createdDate
        createdBy {
          email
        }
        modifiedDate
        closure
        organization
        publishTo
        jobCampus {
          id
          campus {
            id
            name
          }
        }
        jobStates {
          id
          state {
            id
            name
          }
        }
        ShortlistedCount
        totalProfileViewed
        totalCandidates
        numberOfViews
        shorlistedCandidates {
          id
          candidate {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_JOBS_ARR = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $not_candidate: String
  ) {
    jobs(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      not_candidate: $not_candidate
    ) {
      count
      jobs {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB_CANDIDATE_BY_JOB = gql`
  mutation upsertJobCandidate($payload: [JobCandidateUpsertInput!]) {
    upsertJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB_CANDIDATE = gql`
  mutation changeJobCandidateStatus($payload: ChangeJobCandidateStatus!) {
    changeJobCandidateStatus(payload: $payload) {
      status
      error
    }
  }
`;

export const JOB_CANDIDATES = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
      jobcandidates {
        id
        job {
          id
          title
          city {
            id
            name
          }
        }
        candidate {
          id
          firstName
          lastName
          email
          mobile
          skills
        }
        rating
        status
        comments
        createdDate
        history {
          status
          createdDate
        }
      }
    }
  }
`;

export const JOB_CANDIDATES_COUNT = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
    }
  }
`;

export const JOB_CANDIDATES_COUNT_BY_STATUS = gql`
  query getApplicationsCountByStatus {
    getApplicationsCountByStatus {
      appliedCount
      shortlistedCount
      interviewedCount
      selectedCount
      offeredCount
      joinedCount
      rejectedCount
    }
  }
`;

export const GET_JOB_CANDIDATE = gql`
  query getJobCandidate($id: String!) {
    jobCandidate(id: $id) {
      id
      resume {
        id
        resumeName
        resumeType
        inHouseResume
        details
      }
      candidate {
        id
        firstName
        lastName
        document
        documentType
        # workPreference {
        #   id
        # }
        workPref {
          id
          jobType1
          jobType2
          jobFunctionalAreaPreferences
          jobIndustryPreferences
          jobCityPreferences
          maximumExpectation
          minimumExpectation
          internshipJobType1
          internshipJobType2
          internshipFunctionalAreaPreferences
          internshipIndustryPreferences
          internshipCityPreferences
          internshipMaximumExpectation
          internshipMinimumExpectation
        }
        mobile
        email
        isSelfRegistered
        dob
        location
        city {
          id
          name
        }
        # state {
        #   id
        #   name
        # }
        gender
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          collegePercentage
          specialization
          graduationYear
        }
        language {
          language
          read
          write
          speak
        }
        # internshipExperience {
        #   id
        #   company
        #   functionalArea {
        #     id
        #     name
        #   }
        #   industry {
        #     id
        #     name
        #   }
        #   startDate
        #   endDate
        #   designation
        #   # description
        # }
        workExp {
          id
          current
          designation
          company
          functionalArea {
            id
            name
          }
          industry {
            id
            name
          }
          startDate
          endDate
          description
        }
        internshipExp {
          id
          designation
          company
          startDate
          endDate
        }
        skills
        # skillSection
        languagesKnown {
          id
          language
          read
          write
          speak
        }
        language {
          language
          read
          write
          speak
        }
        resumes {
          id
          resumeName
          resumeType
          details
          createdDate
          isInhouseResume
        }
        profile
        # coCurricular
        # location
        facultyRecommendation
        # videoName
        # college {
        #   name
        # }
        # personalDetails
        # mafoiScore
        # education
        # # workexperience
        # skillSection
        # training
        # summary
        # extra
      }
      comments
      status
      createdDate
      modifiedDate
      history {
        status
        createdDate
      }
      job {
        id
        title
        organization
        jobMode
        jobTeamType
        createdDate
        closure
        genderPreference
        industry {
          id
          name
        }
        category {
          id
          name
        }
      }
      interviews {
        id
        startTime
        endTime
        interviewDate
        location
        comments
        status
      }
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation scheduleInterview($payload: InterviewCreateInput!) {
    scheduleInterview(payload: $payload) {
      status
      error
    }
  }
`;

export const EDIT_SCHEDULED_INTERVIEW = gql`
  mutation ediScheduleInterview($payload: InterviewUpdateInput!) {
    updateScheduledInterview(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_JOBS = gql`
  mutation deleteJob($ids: [String!]) {
    deleteJob(ids: $ids) {
      status
      error
    }
  }
`;

export const SEARCH_JOBS = gql`
  query searchJobs($filter: JobFilterInput, $limit: Int, $page: Int) {
    searchJobs(filter: $filter, limit: $limit, page: $page) {
      count
      jobs {
        id
        title
        jobType
        description
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        qualification {
          id
          name
        }
        skills
      }
    }
  }
`;

export const EXPORT_APPLIED_CANDIDATES = gql`
  query exportAppliedCandidates {
    exportAppliedCandidates {
      status
      message
      report
    }
  }
`;
