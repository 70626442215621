import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    text: {
      primary: colors.fontPrimary
    }
  },
  typography: {
    fontFamily: "Roboto"
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 400
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "14px"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: { fontSize: "14px" }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary,
          borderRadius: "4px",
          "& .Mui-disabled": { backgroundColor: "#F4F7FC" }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px"
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: colors.primary,
            borderRadius: "4px"
          },
          "*::-webkit-scrollbar-thumb:hover": {
            backgroundColor: colors.secondary
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1"
          }
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);
