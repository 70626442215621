/**
 * Asynchronously loads the component for CampusFacultyDevelopment
 */

import { lazyLoad } from "utils/loadable";

export const CampusFacultyDevelopment = lazyLoad(
  () => import("./index"),
  (module) => module.CampusFacultyDevelopment
);
