import * as React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Modal from "./CommonModal";
import { LinkedinShareButton, LinkedinIcon } from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { colors } from "styles/colors";
import theme from "styles/theme";

const SocialMediaShare = (props) => {
  const { open, setOpen, id } = props;

  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));

  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    setCopied(false);
  }, [open]);

  /**
   * Constructs the full URL for a student job detail page by combining the base URL from the environment and the job ID.
   */
  const baseURL = process.env.REACT_APP_STAGING_URI;
  const link = `${baseURL}/student-job-detail/${id}`;
  const maxCharacters = matchesXs ? 27 : 60;

  /**
   * Truncates the `link` string to a maximum of `maxCharacters` characters, and appends an ellipsis (`...`) if the original string was longer.
   */
  let truncatedLink = link.slice(0, maxCharacters);
  if (link.length > maxCharacters) {
    truncatedLink += "...";
  }

  const copyToClipboard = () => {
    /**
     * Copies the provided link to the user's clipboard.
     */
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      children={
        <>
          <Grid container spacing={2} p={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "start", mt: -7 }}
            >
              <Typography fontSize={16} fontWeight={600}>
                Share via
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <LinkedinShareButton
                  url={`${baseURL}/student-job-detail/${id}`}
                >
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "start", mt: 0.5 }}>
                <Typography fontSize={11} fontWeight={400}>
                  LinkedIn
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                color={copied ? colors.successGreen : colors.lightGray}
              >
                {copied ? "Link Copied!" : "Copy Link"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "35px",
                  border: "1px solid rgba(164, 165, 168, 0.5)",
                  borderRadius: "4px",
                  display: "flex"
                }}
              >
                <Box
                  sx={{
                    width: "92%",
                    display: "flex",
                    alignItems: "center",
                    ml: 1
                  }}
                >
                  <Typography fontSize={14}>{truncatedLink || ""}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 2,
                    cursor: "pointer"
                  }}
                >
                  <ContentCopyIcon fontSize="small" onClick={copyToClipboard} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default SocialMediaShare;
