/**
 * Asynchronously loads the component for Upskilling
 */

import { lazyLoad } from "utils/loadable";

export const StudentMESAcademicDetails = lazyLoad(
  () => import("./index"),
  (module) => module.StudentMESAcademicDetails
);
