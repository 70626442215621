/**
 * Asynchronously loads the component for AdminDashboard
 */

import { lazyLoad } from "utils/loadable";

export const AdminBroadCast = lazyLoad(
  () => import("./index"),
  (module) => module.AdminBroadCast
);
