import * as React from "react";

const HorizontalDivider = (props) => {
  const { height, mt } = props;
  return (
    <div
      style={{
        height: height || 1,
        backgroundColor: "#E9E9E9",
        width: "100%",
        marginTop: mt || 0
      }}
    />
  );
};

export default HorizontalDivider;
