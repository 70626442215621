import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Typography,
  Avatar,
  Box,
  Button
} from "@mui/material";
import {
  NotificationsNoneOutlined,
  DoneAllOutlined,
  ArrowForwardOutlined
} from "@mui/icons-material";
import { colors } from "../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS
} from "app/graphql/users";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

interface INotificationItemProps {
  content: any;
  history: any;
}

function NotificationItem(props: INotificationItemProps) {
  const { content, history } = props;
  return (
    <MenuItem
      sx={{
        borderLeft: content.unread ? `3px solid ${colors.primary}` : 0,
        backgroundColor: content.unread ? colors.primaryFill : "none",
        borderBottom: "1px solid #E9E9E9"
      }}
      onClick={() => history.push("/employer-notifications")}
    >
      <Grid container py={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={1.7}
              sm={1.7}
              md={1.7}
              lg={1.7}
              sx={{ width: "15%" }}
            >
              <Avatar
                sx={{
                  backgroundColor: colors.primaryBlue,
                  color: colors.secondary,
                  border: `1px solid ${colors.primary}`
                }}
              >
                {content?.verb?.charAt(0)}
              </Avatar>
            </Grid>
            <Grid
              item
              container
              rowSpacing={0.5}
              xs={10.3}
              sm={10.3}
              md={10.3}
              lg={10.3}
              sx={{ width: "80%" }}
            >
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <Typography
                  color={colors.primaryBlue}
                  fontSize="14px"
                  fontWeight={500}
                  noWrap
                >
                  {content?.verb}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={{
                  width: "5%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {content?.unread ? (
                  <FiberManualRecordIcon
                    style={{ fontSize: 14, color: colors.primaryBlue }}
                  />
                ) : (
                  <FiberManualRecordOutlinedIcon
                    style={{ fontSize: 14, color: colors.primaryBlue }}
                  />
                )}
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <Typography fontSize="12px" color={colors.fontLighter2} noWrap>
                  {content?.description ? content?.description : content?.verb}
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  color={colors.fontLighter2}
                  fontSize="12px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {content?.timestamp
                    ? moment(content.timestamp).fromNow()
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MenuItem>
  );
}

const EmployerNotifications = (props) => {
  const { clearNotifications } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [count, setCount] = React.useState(0);

  const { data: rawNotifications, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [markAsRead] = useMutation(MARK_AS_READ_NOTIFICATIONS, {
    onCompleted(data) {
      const { markAsRead } = data;
      const { status } = markAsRead;
      if (status) {
        refetch();
      }
    }
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (rawNotifications && rawNotifications?.notifications) {
      setNotifications(rawNotifications?.notifications);
      let localUnread = 0;
      rawNotifications?.notifications.map((n: any) => {
        if (n.unread) {
          localUnread++;
        }
      });
      setCount(localUnread);
    } else {
      setNotifications([]);
    }
  }, [rawNotifications]);

  React.useEffect(() => {
    if (clearNotifications) {
      refetch();
    }
  }, [clearNotifications]);

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mr: 2 }}
      >
        {count > 0 ? (
          <Badge
            badgeContent={count}
            color="secondary"
            sx={{ "& .MuiBadge-badge": { color: colors.primary } }}
          >
            <NotificationsNoneOutlined style={{ color: colors.fontGrey }} />
          </Badge>
        ) : (
          <NotificationsNoneOutlined style={{ color: colors.fontGrey }} />
        )}
      </IconButton>
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{ width: { xs: 300, sm: 450 } }}
        MenuListProps={{
          sx: {
            py: 0
          }
        }}
      >
        {notifications?.length > 0 ? (
          <>
            <Grid container>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ px: 2, py: 1.5, backgroundColor: colors.primaryBlue }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize="18px" color={colors.secondary}>
                    Notification
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Box
                    sx={{
                      mt: 0.5,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => markAsRead()}
                  >
                    <Typography fontSize="15px" color={colors.secondary}>
                      Mark All as Read
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {notifications &&
              notifications.map((noti, index) => (
                <NotificationItem
                  key={noti.id}
                  content={noti}
                  history={history}
                />
              ))}
            <MenuItem onClick={() => history.push("/employer-notifications")}>
              <Grid container sx={{ py: 1 }}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography color={colors.primaryBlue} fontSize="16px">
                    View all notifications
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </>
        ) : (
          <MenuItem>
            <Typography>No notifications</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default EmployerNotifications;
