import * as React from "react";
import { Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { colors } from "../../styles/colors";

interface ICommonModalProps {
  open: boolean;
  setOpen: any;
  children: React.ReactElement;
  width?: string;
  maxWidth?: any;
  hideCloseIcon?: boolean;
}

const CommonModal = (props: ICommonModalProps) => {
  const { open, setOpen, children, width, maxWidth, hideCloseIcon } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll={"paper"}
      maxWidth={maxWidth ? maxWidth : "sm"}
    >
      <DialogContent
        sx={{
          width: width ? width : "100%"
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-end"
          >
            {!hideCloseIcon && (
              <IconButton
                size="small"
                onClick={() => setOpen(false)}
                sx={{
                  backgroundColor: colors.secondary,
                  borderRadius: 50,
                  position: "absolute",
                  top: 5,
                  right: 5
                  // "&:hover": {
                  //   backgroundColor: colors.primaryBlueDark
                  // }
                }}
              >
                <Clear fontSize="small" sx={{ color: colors.primaryBlue }} />
              </IconButton>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CommonModal;
