import * as React from "react";
import {
  Box,
  Drawer,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import images from "assets/images/images";
import { colors } from "styles/colors";
import { useHistory, useLocation } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import { getStudentNavbarSelected } from "utils/helpers";

const navItems = [
  {
    id: 0,
    title: "Dashboard",
    path: "/student-dashboard",
    image1: images.home_icon_blue,
    image2: images.home_icon_grey
  },
  {
    id: 1,
    title: "Jobs / Internships",
    path: "/student-search-jobs-and-internships",
    image1: images.suitcase_icon_blue,
    image2: images.suitcase_icon_grey
  },
  {
    id: 2,
    title: "Upskilling",
    path: "/student-upskilling",
    external: true,
    image1: images.upskilling_icon_blue,
    image2: images.upskilling_icon_grey
  },
  {
    id: 3,
    title: "Ma Foi Score",
    path: "/student-mafoi-employability-score",
    image1: images.score_icon_blue,
    image2: images.score_icon_grey
  },
  // {
  //   id: 4,
  //   name: "Events",
  //   path: "/student-events",
  //   image1: images.date_icon_blue,
  //   image2: images.date_icon_grey
  // },
  {
    id: 5,
    title: "My Application",
    path: "/student-myapplication",
    image1: images.application_icon_blue,
    image2: images.application_icon_grey
  },
  {
    id: 6,
    title: "Profile",
    path: "/student-profile",
    image1: images.profile_icon_blue,
    image2: images.profile_icon_grey
  },
  {
    id: 7,
    title: "Resume Builder",
    path: "/student-resume-templates",
    image1: images.resume_builder_icon_blue,
    image2: images.resume_builder_icon_grey
  },
  {
    id: 8,
    title: "Notification",
    path: "/student-notifications",
    image1: images.notification_icon_blue,
    image2: images.notification_icon_grey
  }
];

const StudentMobileNavigationDrawer = (props) => {
  const { mobileOpen, setMobileOpen } = props;

  const location = useLocation();

  const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(false);
  };

  const [logoutModal, setLogoutModal] = React.useState(false);
  const token = localStorage.getItem("token") || "";
  const baseURL = process.env.REACT_APP_WORDPRESS_BASE_URI;
  const upskillingURL = `${baseURL}/eb-my-courses/?mo-jwt-token=${token}`;

  const drawer = (
    <Box>
      <Box
        sx={{
          width: "100%",
          px: 2,
          py: 0.75,
          display: "flex",
          borderBottom: `1px solid ${colors.lightGreyBorder}`,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1
          }}
        >
          <img src={images.prosculpt_logo_colored} style={{ width: "120px" }} />
        </Box>
        <IconButton color="primary" edge="start" onClick={handleDrawerToggle}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <List>
        {navItems?.map((item) => {
          if (item?.external) {
            return (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    // console.log("window", window);
                    window?.open(upskillingURL, "_blank");
                  }}
                >
                  <img
                    src={
                      getStudentNavbarSelected(location, item)
                        ? item.image1
                        : item.image2
                    }
                    height={20}
                    alt="My SVG"
                  />
                  <ListItemText
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "0.5px",
                      display: "flex",
                      alignItems: "baseline",
                      ml: 1,
                      mt: 1,
                      color: getStudentNavbarSelected(location, item)
                        ? colors.primaryBlue
                        : colors.fontGrey
                    }}
                    primary={item.title}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
          return (
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => history.push(`${item.path}`)}
              >
                <img
                  src={
                    getStudentNavbarSelected(location, item)
                      ? item.image1
                      : item.image2
                  }
                  height={20}
                  alt="My SVG"
                />
                <ListItemText
                  sx={{
                    fontWeight: 600,
                    letterSpacing: "0.5px",
                    display: "flex",
                    alignItems: "baseline",
                    ml: 1,
                    mt: 1,
                    color: getStudentNavbarSelected(location, item)
                      ? colors.primaryBlue
                      : colors.fontGrey
                  }}
                  primary={item.title}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Box sx={{ px: 2.5, mt: 24 }}>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              mx: "auto",
              py: 1.25,
              borderRadius: 0
            }}
            onClick={() => {
              setLogoutModal(true);
            }}
          >
            Logout
          </Button>
        </Box>
        <LogoutModal
          open={logoutModal}
          setOpen={setLogoutModal}
          width={"340px"}
        />
      </Box>
    </Box>
  );

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true
      }}
      sx={{
        display: { xs: "block", sm: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "100%"
        }
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default StudentMobileNavigationDrawer;
