/**
 * Asynchronously loads the component for StudentAllCommunity
 */

import { lazyLoad } from "utils/loadable";

export const StudentAllCommunity = lazyLoad(
  () => import("./index"),
  (module) => module.StudentAllCommunity
);
