import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { colors } from "../../../styles/colors";
import { getDateInFormat } from "../../../utils/helpers";
import { genders, jobModes, jobTeamTypes } from "../../../utils/constants";

const JobMetaData = (props) => {
  return (
    <Grid container spacing={1} sx={{ my: 3 }}>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Job Type
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props &&
            props.job &&
            props.job.job &&
            props.job.job.jobMode &&
            jobModes[props?.job?.job?.jobMode]}
          /{" "}
          {props &&
            props.job &&
            props.job.job &&
            props.job.job.jobTeamType &&
            jobTeamTypes[props?.job?.job?.jobTeamType]}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Posted On
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {getDateInFormat(props?.job?.job?.createdDate, "DD MMM YYYY")}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Last date for apply
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {getDateInFormat(props?.job?.job?.closure, "DD MMM YYYY")}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Gender Preference
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {genders[props?.job?.job?.genderPreference]}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6} sx={{ mt: 1 }}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Industry
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props?.job?.job?.industry?.name
            ? props?.job?.job?.industry?.name
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={6} sx={{ mt: 1 }}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Functional Area
        </Typography>
        <Typography fontSize="15px" mt={1} color={colors.primaryBlue}>
          {props?.job?.job?.category?.name
            ? props?.job?.job?.category?.name
            : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default JobMetaData;
