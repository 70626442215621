/**
 * Asynchronously loads the component for Forbidden
 */

import { lazyLoad } from "utils/loadable";

export const Forbidden = lazyLoad(
  () => import("./index"),
  (module) => module.Forbidden
);
