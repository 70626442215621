import * as React from "react";
import { Grid, Typography, Chip } from "@mui/material";
import { colors } from "../../../styles/colors";

interface SalaryDetailsProps {
  job: any;
}

const SalaryDetails = (props: SalaryDetailsProps) => {
  const { job } = props;
  const [perks, setPerks] = React.useState([]);

  React.useEffect(() => {
    /**
     * Parses the `job.job.perks` property and sets the `perks` state variable with the parsed values.
     *
     * This function is called when the `job` prop changes, and is used to extract the perks associated with the job.
     * The perks are stored in the `job.job.perks` property as a JSON string, which is parsed and then filtered to only include the keys with truthy values.
     * The resulting array of perks is then set in the `perks` state variable.
     */
    if (job && job?.job && job?.job?.perks) {
      const temp = JSON.parse(job?.job?.perks);
      if (temp) {
        let tempPerks: any = [];
        for (var key in temp) {
          if (temp[key]) {
            tempPerks.push(key);
          }
        }
        setPerks(tempPerks);
      }
    }
  }, [job]);

  const perksData = {
    freeLunch: "Free Lunch",
    transport: "Transport",
    freeSnacks: "Free Snacks",
    fiveDayWork: "Five Day Work"
  };

  // console.log({ perks });

  return (
    <Grid container spacing={1} sx={{ my: 3 }}>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 1 }}>
        <Typography fontSize="16px" fontWeight={500}>
          Salary Details
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Typography fontSize="14px" color={colors.lightGrey}>
          Salary Amount
        </Typography>
        {props?.job?.job?.salaryConfidential ? (
          <Typography color={colors.primaryBlue} fontSize="15px" mt={1}>
            ** to **
          </Typography>
        ) : props?.job?.job?.stipendType === "fixed" ? (
          <Typography color={colors.primaryBlue} fontSize="15px" mt={1}>
            ₹{" "}
            {props?.job?.job?.minimumRemuneration
              ? props?.job?.job?.minimumRemuneration
              : "*"}
          </Typography>
        ) : props?.job?.job?.stipendType === "noStipend" ? (
          <Typography color={colors.fontLight} fontSize="15px" mt={1}>
            No Stipend
          </Typography>
        ) : (
          <Typography color={colors.primaryBlue} fontSize="15px" mt={1}>
            ₹{" "}
            {props?.job?.job?.minimumRemuneration
              ? props?.job?.job?.minimumRemuneration
              : "*"}{" "}
            to ₹{" "}
            {props?.job?.job?.maximumRemuneration
              ? props?.job?.job?.maximumRemuneration
              : "*"}
          </Typography>
        )}
      </Grid>
      <Grid item container lg={9} md={9} sm={9} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
          <Typography fontSize="14px" color={colors.lightGrey}>
            Perks
          </Typography>
        </Grid>
        <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} mb={1}>
          {perks && perks?.length > 0 ? (
            perks.map((perk) => (
              <Grid key={perk} item>
                <Chip
                  label={perksData[perk] || "-"}
                  sx={{
                    backgroundColor: colors.lightBlueChip,
                    color: colors.primaryBlue,
                    borderRadius: "2px"
                  }}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>-</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalaryDetails;
