/**
 * Asynchronously loads the component for Login
 */

import { lazyLoad } from "utils/loadable";

export const StudentRegistration = lazyLoad(
  () => import("./index"),
  (module) => module.StudentRegistration
);
