import * as React from "react";
import { Typography } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";

const CommonFooter = (props) => {
  return (
    <Typography
      fontSize="14px"
      sx={{ py: 1.5, backgroundColor: "#F4F7FC", mt: 5 }}
      textAlign="center"
    >
      {`© ${dayjs().format("YYYY")} Campus Tech. All rights reserved`}
    </Typography>
  );
};

export default CommonFooter;
