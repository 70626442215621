import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Typography, Box, Avatar, Button } from "@mui/material";
import {
  EmailOutlined,
  CallOutlined,
  ManOutlined,
  LocationOnOutlined,
  FiberManualRecordOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { getMIMETypeOfFile, genders } from "../../utils/constants";
import { colors } from "../../styles/colors";

import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_STUDENT_DETAILS,
  GET_PROFILE_PHOTO,
} from "../graphql/StudentProfile";
import getStore from "../../store/zustandStore";
import dayjs from "dayjs";
import {
  downloadAny,
  formDateFromStringYYYYMMDD,
  getDateInFormat,
} from "utils/helpers";
import TranscriptsDetails from "./TranscriptsDetails";
import { useReactToPrint } from "react-to-print";
import { GET_RESUME_PDF } from "app/graphql/users";

const Title = (props) => {
  const { title } = props;
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
      <Grid container spacing={1} alignItems="center">
        <Grid
          item
          lg={
            title === "Extra Curricular Achivements" ||
            title === "Faculty Recommendations" ||
            title === "Papers Presented/Published"
              ? 3
              : 2
          }
        >
          <Typography fontSize="15px" fontWeight={700}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          lg={
            title === "Extra Curricular Achivements" ||
            title === "Faculty Recommendations" ||
            title === "Papers Presented/Published"
              ? 9
              : 10
          }
        >
          <Box
            sx={{
              opacity: "0.05",
              border: "2px solid #212121",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ResumeTemplate = (props) => {
  const { studentId }: any = useParams();
  const history = useHistory();
  const setLoading = getStore((state: any) => state.setLoading);
  const componentRef = React.useRef();

  const [profilePhoto, setProfilePhoto] = React.useState<any>(null);
  const [studentData, setStudentData] = React.useState<any>(null);
  const [profileField, setProfileField] = React.useState<any>(null);
  const [studentQualifications, setStudentQualifications] = React.useState<any>(
    []
  );
  const [studentWorkExperience, setStudentWorkExperience] = React.useState<any>(
    []
  );
  const [studentInternshipExperience, setStudentInternshipExperience] =
    React.useState<any>([]);
  const [studentSkills, setstudentSkills] = React.useState<any>([]);
  const [studentLanguages, setStudentLanguages] = React.useState<any>([]);
  const [studentCertifications, setStudentCertifications] = React.useState<any>(
    []
  );
  const [studentCocurricular, setStudentCocurricular] =
    React.useState<any>(null);
  const [transcripts, setTranscripts] = React.useState<any>([]);

  const { data: studentDetails, loading } = useQuery(GET_STUDENT_DETAILS, {
    variables: { id: studentId },
    skip: !studentId,
  });

  const { data: profilePic, loading: loadingPhoto } = useQuery(
    GET_PROFILE_PHOTO,
    {
      variables: {},
    }
  );

  React.useEffect(() => {
    if (profilePic && profilePic.getProfilePicture) {
      setProfilePhoto(profilePic.getProfilePicture);
    }
  }, [profilePic]);

  React.useEffect(() => {
    if (studentDetails && studentDetails.user) {
      setStudentData(studentDetails.user);
      if (studentDetails.user?.profile) {
        const profileJSON = JSON.parse(studentDetails.user?.profile);
        if (profileJSON) {
          setProfileField(profileJSON);
        }
      }
      if (studentDetails.user?.qualification) {
        setStudentQualifications(studentDetails.user?.qualification);
        let temp: any = [];
        studentDetails.user?.qualification.map((qual) => {
          if (qual?.transcripts) {
            temp.push(qual);
          }
        });
        setTranscripts(temp);
      }
      if (studentDetails.user?.workExperience) {
        setStudentWorkExperience(studentDetails.user?.workExperience);
      }
      if (studentDetails.user?.internshipExperience) {
        setStudentInternshipExperience(
          studentDetails.user?.internshipExperience
        );
      }
      if (studentDetails.user?.skills) {
        const skillsJSON = JSON.parse(studentDetails.user?.skills);
        if (skillsJSON) {
          setstudentSkills(skillsJSON);
        }
      }
      if (studentDetails.user?.language) {
        setStudentLanguages(studentDetails.user?.language);
      }
      if (studentDetails.user?.certifications) {
        const certiJSON = JSON.parse(studentDetails.user?.certifications);
        if (certiJSON) {
          setStudentCertifications(certiJSON);
        }
      }
      if (studentDetails.user?.coCurricular) {
        const coCurriJSON = JSON.parse(studentDetails.user?.coCurricular);
        if (coCurriJSON) {
          setStudentCocurricular(coCurriJSON);
        }
      }
    }
  }, [studentDetails]);

  const handlePrint = useReactToPrint({
    content: () => {
      if (componentRef && componentRef.current) {
        return componentRef.current;
      }
      return null;
    },
  });

  const [getResumePdf] = useLazyQuery(GET_RESUME_PDF, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  React.useEffect(() => {
    setLoading(loading || loadingPhoto);
  }, [loading, loadingPhoto, setLoading]);

  return (
    <Box sx={{ backgroundColor: colors.strokeGray }}>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 2, px: 5 }}>
        <Button onClick={() => history.push("/student-dashboard")}>
          Back to Dashboard
        </Button>
        <Button
          onClick={() =>
            getResumePdf({
              variables: { id: studentDetails?.user?.id },
              onCompleted: (data) => {
                const { downloadResume } = data || {};
                const { file } = downloadResume || {};
                if (file) {
                  downloadAny(file, "Resume.pdf", "pdf");
                }
              },
            })
          }
          startIcon={<FileDownloadOutlined color="primary" fontSize="small" />}
        >
          Export
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.strokeGray,
          display: "flex",
          justifyContent: "center",
          py: 3,
          px: 30,
        }}
      >
        <Box ref={componentRef}>
          <Box sx={{ backgroundColor: colors.secondary, p: 4 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={2} md={2} sm={3} xs={12}>
                {profilePhoto && profilePhoto.file ? (
                  // <Avatar
                  //   variant="rounded"
                  //   src={`data:image/${getMIMETypeOfFile(
                  //     profilePhoto.fileType
                  //   )};base64,${profilePhoto.file}`}
                  //   sx={{
                  //     width: 110,
                  //     height: 110,
                  //   }}
                  <img
                    src={`data:image/${getMIMETypeOfFile(
                      profilePhoto.fileType
                    )};base64,${profilePhoto.file}`}
                    height={110}
                    width={110}
                    style={{ borderRadius: 4 }}
                  />
                ) : (
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: colors.secondary,
                      color: colors.primary,
                      border: `1px solid ${colors.primary}`,
                      width: 110,
                      height: 110,
                    }}
                  >
                    {studentData?.firstName
                      ? studentData.firstName.charAt(0)
                      : ""}
                  </Avatar>
                )}
              </Grid>
              <Grid item lg={10} md={10} sm={9} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography fontSize="20px" fontWeight={700}>
                      {studentData?.firstName || ""}{" "}
                      {studentData?.lastName || ""}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
                    <Typography fontSize="12px" color="primary">
                      {studentData?.college?.name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <EmailOutlined color="primary" fontSize="small" />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="10px" color={colors.lighterGray}>
                          {studentData?.email || ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <CallOutlined color="primary" fontSize="small" />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="10px" color={colors.lighterGray}>
                          {studentData?.mobile
                            ? `+(91)${studentData.mobile}`
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ManOutlined color="primary" fontSize="small" />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="10px" color={colors.lighterGray}>
                          {studentData?.gender
                            ? genders[studentData.gender]
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LocationOnOutlined color="primary" fontSize="small" />
                      </Grid>
                      <Grid item>
                        <Typography fontSize="10px" color={colors.lighterGray}>
                          {studentData?.state && studentData?.city
                            ? `${studentData?.city?.name || ""}, ${
                                studentData?.state?.name || ""
                              }`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Title title="Qualification" />
              <Grid item lg={2} md={2} sm={2} xs={4}>
                <Typography color="primary" fontSize="12px">
                  {profileField && profileField?.class10?.gradYear
                    ? getDateInFormat(profileField.class10?.gradYear, "YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={6}>
                <Typography fontSize="13px" fontWeight={500}>
                  10th Class
                </Typography>
                <Typography fontSize="13px" color={colors.lighterGray}>
                  {profileField && profileField?.class10?.school
                    ? profileField?.class10?.school
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={2}
                xs={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Typography fontSize="13px" color={colors.lighterGray}>
                  {profileField && profileField.class10?.percentage
                    ? `${profileField.class10?.percentage}%`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={4}>
                <Typography color="primary" fontSize="12px">
                  {profileField && profileField?.class12?.gradYear
                    ? getDateInFormat(profileField.class12?.gradYear, "YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={6}>
                <Typography fontSize="13px" fontWeight={500}>
                  12th Class
                </Typography>
                <Typography fontSize="13px" color={colors.lighterGray}>
                  {profileField && profileField?.class12?.school
                    ? profileField?.class12?.school
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                md={2}
                sm={2}
                xs={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Typography fontSize="13px" color={colors.lighterGray}>
                  {profileField && profileField.class12?.percentage
                    ? `${profileField.class12?.percentage}%`
                    : "-"}
                </Typography>
              </Grid>
              {studentQualifications &&
                studentQualifications.length > 0 &&
                studentQualifications.map((qual) => (
                  <>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                      <Typography color="primary" fontSize="12px">
                        {qual.graduationYear
                          ? qual.graduationYear.substring(0, 4)
                          : "xxxx"}
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={6}>
                      <Typography fontSize="13px" fontWeight={500}>
                        {qual?.course?.name || ""}
                      </Typography>
                      <Typography fontSize="13px" color={colors.lighterGray}>
                        {qual?.college || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Typography fontSize="13px" color={colors.lighterGray}>
                        {qual?.collegePercentage
                          ? `${qual.collegePercentage}%`
                          : ""}
                      </Typography>
                    </Grid>
                  </>
                ))}
              {studentWorkExperience && studentWorkExperience.length > 0 && (
                <Title title="Experience" />
              )}
              {studentWorkExperience &&
                studentWorkExperience.length > 0 &&
                studentWorkExperience.map((exp) => (
                  <>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                      <Typography color="primary" fontSize="12px">
                        {exp.startDate ? exp.startDate.substring(0, 4) : "xxxx"}
                        -
                        {exp?.current
                          ? "Presesnt"
                          : exp.endDate
                          ? exp.endDate.substring(0, 4)
                          : "xxxx"}
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={8}>
                      <Typography fontSize="13px" fontWeight={500}>
                        {exp?.designation || ""}
                      </Typography>
                      <Typography fontSize="13px" color={colors.lighterGray}>
                        {exp?.company || ""}
                      </Typography>
                    </Grid>
                  </>
                ))}
              {studentInternshipExperience &&
                studentInternshipExperience.length > 0 &&
                studentInternshipExperience.map((exp) => (
                  <>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <Typography color="primary" fontSize="12px">
                        {exp?.startDate
                          ? dayjs(
                              formDateFromStringYYYYMMDD(exp?.startDate)
                            ).format("MMM YYYY")
                          : "xxxx"}
                        -
                        {exp.endDate
                          ? dayjs(
                              formDateFromStringYYYYMMDD(exp?.endDate)
                            ).format("MMM YYYY")
                          : "xxxx"}
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Typography fontSize="13px" fontWeight={500}>
                        {exp?.designation || ""}
                      </Typography>
                      <Typography fontSize="13px" color={colors.lighterGray}>
                        {exp?.company || ""}
                      </Typography>
                    </Grid>
                  </>
                ))}
              {studentSkills && studentSkills.length > 0 && (
                <Title title="Skills" />
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  {studentSkills &&
                    studentSkills.length > 0 &&
                    studentSkills.map((skill) => (
                      <>
                        <Grid item>
                          <FiberManualRecordOutlined
                            color="primary"
                            fontSize="small"
                          />
                        </Grid>
                        <Grid item sx={{ mr: 1 }}>
                          <Typography fontSize="13px">{skill}</Typography>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </Grid>
              {studentLanguages && studentLanguages.length > 0 && (
                <Title title="Languages" />
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  {studentLanguages &&
                    studentLanguages.length > 0 &&
                    studentLanguages.map((lan) => (
                      <Grid item>
                        <Typography fontSize="13px">{lan.language}</Typography>
                        <Typography fontSize="10px">
                          {lan?.read ? "Read" : ""} -{" "}
                          {lan?.write ? "Write" : ""} -{" "}
                          {lan?.speak ? "Speak" : ""}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              {studentCocurricular &&
                studentCocurricular?.projects &&
                studentCocurricular?.projects.length > 0 &&
                studentCocurricular?.projects[0].title && (
                  <Title title="Projects" />
                )}
              {studentCocurricular &&
                studentCocurricular?.projects &&
                studentCocurricular?.projects.length > 0 &&
                studentCocurricular?.projects[0].title &&
                studentCocurricular?.projects.map((proj) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {proj?.title || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {proj?.description || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {studentCertifications && studentCertifications.length > 0 && (
                <Title title="Certifications" />
              )}
              {studentCertifications &&
                studentCertifications.length > 0 &&
                studentCertifications.map((certi) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {certi?.name || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {certi?.org || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {studentCocurricular &&
                studentCocurricular?.papers &&
                studentCocurricular?.papers.length > 0 &&
                studentCocurricular?.papers[0].title && (
                  <Title title="Papers Presented/Published" />
                )}
              {studentCocurricular &&
                studentCocurricular?.papers &&
                studentCocurricular?.papers.length > 0 &&
                studentCocurricular?.papers[0].title &&
                studentCocurricular?.papers.map((paper) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {paper?.title || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {paper?.event || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {studentCocurricular &&
                studentCocurricular?.scholarships &&
                studentCocurricular?.scholarships.length > 0 &&
                studentCocurricular?.scholarships[0].title && (
                  <Title title="Scholarships Won" />
                )}
              {studentCocurricular &&
                studentCocurricular?.scholarships &&
                studentCocurricular?.scholarships.length > 0 &&
                studentCocurricular?.scholarships[0].title &&
                studentCocurricular?.scholarships.map((scholarship) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {scholarship?.title || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {scholarship?.description || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {studentCocurricular &&
                studentCocurricular?.hackathons &&
                studentCocurricular?.hackathons.length > 0 &&
                studentCocurricular?.hackathons[0].title && (
                  <Title title="Hackathons" />
                )}
              {studentCocurricular &&
                studentCocurricular?.hackathons &&
                studentCocurricular?.hackathons.length > 0 &&
                studentCocurricular?.hackathons[0].title &&
                studentCocurricular?.hackathons.map((hack) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {hack?.title || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {hack?.project || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {studentCocurricular &&
                studentCocurricular?.achievements &&
                studentCocurricular?.achievements.length > 0 &&
                studentCocurricular?.achievements[0].title && (
                  <Title title="Extra Curricular Achivements" />
                )}
              {studentCocurricular &&
                studentCocurricular?.achievements &&
                studentCocurricular?.achievements.length > 0 &&
                studentCocurricular?.achievements[0].title &&
                studentCocurricular?.achievements.map((achievement) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FiberManualRecordOutlined
                          color="primary"
                          fontSize="small"
                        />
                      </Grid>
                      <Grid item sx={{ mr: 1 }}>
                        <Typography fontSize="13px">
                          {achievement?.title || ""}
                        </Typography>
                        <Typography fontSize="10px">
                          {achievement?.description || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Box>
          {transcripts &&
            transcripts.length > 0 &&
            transcripts.map((data) => (
              <Box sx={{ py: 3 }}>
                <TranscriptsDetails data={data} />
              </Box>
            ))}
        </Box>
      </Box>
      {/* {transcripts &&
        transcripts.length > 0 &&
        transcripts.map((data) => (
          <Box
            sx={{
              backgroundColor: colors.strokeGray,
              display: "flex",
              justifyContent: "center",
              py: 3,
            }}
          >
            <TranscriptsDetails data={data} />
          </Box>
        ))} */}
    </Box>
  );
};

export default ResumeTemplate;
