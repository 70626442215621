import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(payload: { username: $username, password: $password }) {
      token
      status
      me {
        id
        firstName
        lastName
        mobile
        email
        organization {
          status
          id
          name
        }
        college {
          status
          id
          name
        }
      }
    }
  }
`;

export const CHECK_USER_EXISTENCE = gql`
  query checkUserExistence($email: String!) {
    checkUserExistence(email: $email) {
      status
    }
  }
`;

export const REGISTER = gql`
  mutation registerUserWithLink($payload: UserRegisterWithLinkInput!) {
    registerUserWithLink(payload: $payload) {
      status
      error
    }
  }
`;

export const CREATE_B2C_STUDENT = gql`
  mutation createStudent($payload: StudentCreateInput!) {
    createStudent(payload: $payload) {
      status
      error
    }
  }
`;

export const CREATE_B2C_STUDENT_FROM_ADMIN = gql`
  mutation createStudentFromAdmin($payload: StudentCreateInput!) {
    createStudentFromAdmin(payload: $payload) {
      status
      error
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($payload: ResetPasswordRequest!) {
    resetPasswordRequest(payload: $payload) {
      status
      error
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($id: String!, $token: String!, $password: String!) {
    setPassword(payload: { token: $token, id: $id, password: $password }) {
      status
      error
      token
      me {
        id
        firstName
        lastName
        mobile
        organization {
          status
          id
          name
        }
        college {
          status
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_JOBS = gql`
  query userJobStatus(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $isApplied: Boolean
    $recommendedJobs: Boolean
    $recommendedInternships: Boolean
  ) {
    userJobStatus(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      is_applied: $isApplied
      recommended_jobs: $recommendedJobs
      recommended_internships: $recommendedInternships
    ) {
      count
      jobs {
        id
        title
        jobType
        description
        qualification {
          id
          name
        }
        category {
          id
          name
        }
        skills
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        isSaved
        jobStatus
        createdDate
        modifiedDate
        organization
        salaryConfidential
      }
    }
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query allLocations {
    allCities {
      id
      name
    }
  }
`;

export const GET_TEMPLATE = gql`
  query DataTemplate {
    dataTemplate {
      template
    }
  }
`;

export const BULK_UPLOAD = gql`
  mutation bulkUploadCandidates($payload: [BulkUploadCandidatesInput!]!) {
    bulkUploadCandidates(payload: $payload) {
      status
      error
      users {
        email
        errorMessage
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($payload: UserCreateInput!, $file: Upload) {
    createUser(payload: $payload, file: $file) {
      status
      error
      user {
        email
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($payload: UserUpdateInput!, $file: Upload) {
    updateUser(payload: $payload, file: $file) {
      status
      error
      user {
        email
      }
    }
  }
`;

export const GET_APPLICATION_COUNT = gql`
  query userJobStatus(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $isApplied: Boolean
  ) {
    userJobStatus(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      is_applied: $isApplied
    ) {
      count
    }
  }
`;

export const DELETE_USERS = gql`
  mutation deleteUsers($ids: [String!]) {
    deleteUsers(ids: $ids) {
      status
      error
    }
  }
`;

export const DELIST_STUDENT = gql`
  mutation delistStudents($ids: [String!]) {
    delistStudents(ids: $ids) {
      status
      error
    }
  }
`;

export const GET_ALL_USERS = gql`
  query users(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $is_student: Boolean
    $sortBy: String
  ) {
    users(
      filter: $filter
      limit: $limit
      page: $page
      is_student: $is_student
      sort_by: $sortBy
    ) {
      count
      users {
        id
        firstName
        lastName
        mobile
        email
        dob
        city {
          id
          name
        }
        gender
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          graduationYear
          specialization
          collegePercentage
          college
        }
        workPreference {
          functionalArea {
            id
            name
          }
          jobType1
          jobType2
          jobLocation
          minimumExpectation
          maximumExpectation
        }
        skills
        languagesKnown {
          language
          read
          write
          speak
        }
        workExperience {
          id
          company
          designation
          startDate
          endDate
        }
        status
      }
    }
  }
`;

export const EXPORT_STUDENTS = gql`
  query exportStudents(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $is_student: Boolean
  ) {
    exportStudents(
      filter: $filter
      limit: $limit
      page: $page
      is_student: $is_student
    ) {
      report
    }
  }
`;

export const COLLEGE_INDUSTRY_CONNECT = gql`
  mutation campusIndustryConnect($payload: CampusIndustryConnectInput!) {
    campusIndustryConnect(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_UPSKILLING_URL = gql`
  query getUpskillingUrl {
    getUpskillingUrl {
      status
      error
      loginurl
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      verb
      unread
      id
      timestamp
      description
      level
    }
  }
`;

export const MARK_AS_READ_NOTIFICATIONS = gql`
  mutation markAsRead {
    markAsRead {
      status
      error
    }
  }
`;

export const GET_RESUME_PDF = gql`
  query downloadResume($id: String!) {
    downloadResume(id: $id) {
      file
    }
  }
`;

export const SEARCH_USER_JOBS = gql`
  query searchJobs(
    $filter: SearchJobFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    searchJobs(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
      jobs {
        id
        title
        jobType
        jobMode
        jobTeamType
        description
        qualification {
          id
          name
        }
        category {
          id
          name
        }
        skills
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        isSaved
        jobStatus
        createdDate
        modifiedDate
        organization
        salaryConfidential
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  query sendMail($payload: EmailPayload!) {
    sendMail(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_EMPLOYER_SUB_USERS = gql`
  query getOrganizationEmployees(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    getOrganizationEmployees(
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
    ) {
      count
      users {
        id
        firstName
        lastName
        mobile
        email
        designation
        roles
        status
        createdDate
      }
    }
  }
`;

export const GET_ALL_ADMIN_USERS = gql`
  query getAdmins(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    getAdmins(filter: $filter, limit: $limit, page: $page, sort_by: $sort_by) {
      count
      users {
        id
        firstName
        lastName
        email
        mobile
        createdDate
        designation
      }
    }
  }
`;

export const GET_SUBSCRIPTION_UTILIZATION_COUNT = gql`
  query getSubscriptionUtilizationCount {
    getSubscriptionUtilizationCount {
      status
      message
      response {
        utilizedJobs
        utilizedSeats
        utilizedDatabase
        organizationSubscription {
          id
          name
          seats
          students
          jobs
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      designation
      landlineNumber
      email
    }
  }
`;
