/**
 * Asynchronously loads the component for EmployerNotifications
 */

import { lazyLoad } from "utils/loadable";

export const EmployerNotifications = lazyLoad(
  () => import("./index"),
  (module) => module.EmployerNotifications
);
