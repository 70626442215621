import { gql } from "@apollo/client";

export const GET_STUDENT_DETAILS = gql`
  query getStudentDetails($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      document
      documentType
      mobile
      email
      isSelfRegistered
      address
      city {
        id
        name
      }
      # state {
      #   id
      #   name
      # }
      country {
        id
        name
      }
      dob
      gender
      # roles
      qualification {
        id
        qualification {
          id
          name
        }
        course {
          id
          name
        }
        graduationYear
        specialization
        collegePercentage
        # enrolmentNumber
        # transcripts
        # current
        college
      }
      language {
        language
        read
        write
        speak
      }
      # certifications
      skills
      profile
      jobDetails {
        jobTitle
        jobLocation
        createdDate
        jobStatus
        jobType
      }
      workPreference {
        id
        # industryId
        # functionalAreaId
        # functionalArea {
        #   id
        #   name
        # }
        # industry {
        #   id
        #   name
        # }
        jobFunctionalAreaPreferences
        jobIndustryPreferences
        jobCityPreferences
        jobType1
        jobType2
        jobLocation
        # jobState {
        #   id
        #   name
        # }
        # jobCity {
        #   id
        #   name
        # }
        minimumExpectation
        maximumExpectation
        # internshipIndustryId
        # internshipFunctionalAreaId
        # internshipFunctionalArea {
        #   id
        #   name
        # }
        # internshipIndustry {
        #   id
        #   name
        # }
        internshipFunctionalAreaPreferences
        internshipIndustryPreferences
        internshipCityPreferences
        internshipJobType1
        internshipJobType2
        internshipJobLocation
        # internshipState {
        #   id
        #   name
        # }
        # internshipCity {
        #   id
        #   name
        # }
        internshipMinimumExpectation
        internshipMaximumExpectation
      }
      workExperience {
        id
        current
        designation
        company
        functionalArea {
          id
          name
        }
        industry {
          id
          name
        }
        startDate
        endDate
        description
      }
      internshipExperience {
        id
        company
        functionalArea {
          id
          name
        }
        industry {
          id
          name
        }
        startDate
        endDate
        designation
        description
      }
      # coCurricular
      # location
      facultyRecommendation
      # videoName
      college {
        name
      }
      personalDetails
      mafoiScore
      education
      # workexperience
      skillSection
      training
      summary
      extra
    }
  }
`;

export const GET_STUDENT_DETAILS_FOR_EMPLOYER = gql`
  query viewCandidateProfile($id: String!) {
    viewCandidateProfile(id: $id) {
      status
      message
      user {
        id
        firstName
        lastName
        document
        documentType
        mobile
        email
        isSelfRegistered
        address
        city {
          id
          name
        }
        # state {
        #   id
        #   name
        # }
        country {
          id
          name
        }
        dob
        gender
        # roles
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          graduationYear
          specialization
          collegePercentage
          # enrolmentNumber
          # transcripts
          # current
          college
        }
        language {
          language
          read
          write
          speak
        }
        # certifications
        skills
        profile
        jobDetails {
          jobTitle
          jobLocation
          createdDate
          jobStatus
          jobType
        }
        workPreference {
          id
          # industryId
          # functionalAreaId
          # functionalArea {
          #   id
          #   name
          # }
          # industry {
          #   id
          #   name
          # }
          jobFunctionalAreaPreferences
          jobIndustryPreferences
          jobCityPreferences
          jobType1
          jobType2
          jobLocation
          # jobState {
          #   id
          #   name
          # }
          # jobCity {
          #   id
          #   name
          # }
          minimumExpectation
          maximumExpectation
          # internshipIndustryId
          # internshipFunctionalAreaId
          # internshipFunctionalArea {
          #   id
          #   name
          # }
          # internshipIndustry {
          #   id
          #   name
          # }
          internshipFunctionalAreaPreferences
          internshipIndustryPreferences
          internshipCityPreferences
          internshipJobType1
          internshipJobType2
          internshipJobLocation
          # internshipState {
          #   id
          #   name
          # }
          # internshipCity {
          #   id
          #   name
          # }
          internshipMinimumExpectation
          internshipMaximumExpectation
        }
        workExperience {
          id
          current
          designation
          company
          functionalArea {
            id
            name
          }
          industry {
            id
            name
          }
          startDate
          endDate
          description
        }
        internshipExperience {
          id
          company
          functionalArea {
            id
            name
          }
          industry {
            id
            name
          }
          startDate
          endDate
          designation
          description
        }
        # coCurricular
        # location
        facultyRecommendation
        # videoName
        college {
          name
        }
        personalDetails
        mafoiScore
        education
        # workexperience
        skillSection
        training
        summary
        extra
      }
    }
  }
`;

export const GET_STUDENT_DETAILS_FOR_RESUME_BUILDER = gql`
  query getStudentDetailsForResumeBuilder($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      email
      personalDetails
      mafoiScore
      education
      workexperience
      skillSection
      training
      summary
      extra
    }
  }
`;

export const GET_MES_RESUME_SECTIONS = gql`
  query getResumeMesSections {
    getResumeMesSections {
      status
      message
      mafoiScore
      assessments {
        name
        code
        score
        sections {
          name
          score
          code
          isIncluded
        }
      }
    }
  }
`;

export const UPDATE_STUDENT_PROFILE = gql`
  mutation updateStudent(
    $payload: UserUpdateInput!
    $file: Upload
    $resume: Upload
  ) {
    updateUser(payload: $payload, file: $file, resume: $resume) {
      status
      error
    }
  }
`;

export const GET_PROFILE_PHOTO = gql`
  query getProfilePicture {
    getProfilePicture {
      fileType
      file
    }
  }
`;

export const UPLOAD_PROFILE_PHOTO = gql`
  mutation uploadProfilePicture($file: Upload) {
    uploadProfilePicture(payload: $file) {
      status
      error
    }
  }
`;

export const GET_VIDEO_INTRODUCTION = gql`
  query getVideoIntroduction($userId: String!) {
    getVideoIntroduction(userId: $userId) {
      file
      fileType
      fileName
      videoUploadDate
    }
  }
`;

export const UPLOAD_VIDEO_INTRODUCTION = gql`
  mutation updateVideo($payload: String!, $file: Upload) {
    uploadVideoIntroduction(payload: $payload, file: $file) {
      status
      error
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($payload: ChangePasswordInput!) {
    changePassword(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_COLLEGE_LOGO = gql`
  query getCampusLogo {
    campusLogo {
      document
      documentType
      documentName
    }
  }
`;

export const GET_USER_ASSESSMENTS = gql`
  query user_assessments {
    getUserAssessments {
      userAssessments
    }
  }
`;

export const UPLOAD_VIDEO_INTRO = gql`
  mutation UploadVideoIntro($payload: UploadVideoIntroduction) {
    UploadVideoIntro(payload: $payload) {
      status
      error
    }
  }
`;
