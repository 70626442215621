export const statusArr = [
  { id: 4, name: "Applied", color: "#007BFF" },
  { id: 5, name: "Shortlisted", color: "#FFC107" },
  { id: 6, name: "Interviewed", color: "#FE6A33" },
  { id: 18, name: "Selected", color: "#6F42C1" },
  { id: 7, name: "Offered", color: "#28A745" },
  { id: 13, name: "Joined", color: "#006400" },
  { id: 9, name: "Rejected", color: "#DC3545" }
];

export const jobStatusArr = [
  { id: 1, name: "Active" },
  { id: 21, name: "Inactive" },
  { id: 0, name: "Pending Approval" },
  { id: 16, name: "Closed" },
  { id: 22, name: "Expired" }
];

export const userRoles = [
  {
    id: "employer_user",
    name: "Admin"
  },
  {
    id: "sub_user",
    name: "Sub User"
  }
];

export const sortByArr = [
  { id: 1, name: "Latest" },
  { id: 2, name: "Oldest" }
];

export const sortByArr2 = [
  { id: "-created_date", name: "Latest" },
  { id: "created_date", name: "Oldest" }
];

export const statusMappingBg = {
  4: "#007BFF",
  5: "#FFC107",
  6: "#FE6A33",
  7: "#6F42C1",
  18: "#28A745",
  13: "#006400",
  9: "#DC3545",
  1: "#007BFF",
  21: "#DC3545",
  0: "#007BFF",
  16: "#DC3545",
  22: "#DC3545"
};

export const statusMappingDisplay = {
  1: "active",
  3: "deleted",
  4: "applied",
  5: "shortlisted",
  6: "interviewed",
  7: "offered",
  13: "joined",
  9: "rejected",
  17: "draft"
};

export const statusMapping = {
  0: "Pending Approval",
  1: "Active",
  3: "Deleted",
  4: "Applied",
  5: "Shortlisted",
  6: "Interviewed",
  7: "Offered",
  13: "Joined",
  9: "Rejected",
  18: "Selected",
  17: "Draft",
  16: "Closed",
  20: "Cleared",
  15: "Put On Hold",
  21: "Inactive",
  22: "Expired"
};

export const jobOrInternshipArr = [
  { id: "INTERNSHIP", name: "Internship" },
  { id: "JOB", name: "Job" }
];

export const genders = {
  M: "Male",
  F: "Female",
  O: "Others",
  B: "Both",
  NA: "No Preference"
};

export const gendersArr = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
  { id: "O", name: "Others" },
  { id: "B", name: "Both" },
  { id: "NA", name: "No Preference" }
];

export const gendersArrForStudentRegistration = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
  { id: "O", name: "Others" }
];

export const jobModes = {
  FULL_TIME: "Full Time",
  PART_TIME: "Part Time"
};

export const experienceDropdown = [
  { id: 1, name: "Fresher", min: 0 },
  { id: 2, name: "0 - 1 Year", min: 0, max: 1 },
  { id: 3, name: "1 - 2 Years", min: 1, max: 2 },
  { id: 4, name: "3+ Years", min: 3 }
];

export const jobModesArr = [
  { id: "FULL_TIME", name: "Full Time" },
  { id: "PART_TIME", name: "Part Time" }
];

export const jobTeamTypes = {
  IN_OFFICE: "In Office",
  WORK_FROM_HOME: "Remote",
  HYBRID: "Hybrid"
};

export const jobTeamTypesArr = [
  { id: "IN_OFFICE", name: "In Office" },
  { id: "WORK_FROM_HOME", name: "Remote" },
  { id: "HYBRID", name: "Hybrid" }
];

export const ownershipArr = [
  { id: "CENTRAL_OPEN_UNIVERSITY", name: "Central Open University" },
  { id: "CENTRAL_UNIVERSITY", name: "Central University" },
  { id: "DEEMED_UNIVERSITY_GOVERNMENT", name: "Deemed University- Government" },
  {
    id: "DEEMED_UNIVERSITY_GOVERNMENT_AIDED",
    name: "DEEMED_UNIVERSITY_GOVERNMENT_AIDED"
  },
  { id: "DEEMED_UNIVERSITY_PRIVATE", name: "Deemed University - Private" },
  {
    id: "INSTITUTE_OF_NATIONAL_IMPORTANCE",
    name: "INSTITUTE_OF_NATIONAL_IMPORTANCE"
  },
  {
    id: "INSTITUTE_UNDER_STATE_LEGISLATURE_ACT",
    name: "Institute Under State Legislature Act"
  },
  { id: "STATE_OPEN_UNIVERSITY", name: "State Open University" },
  {
    id: "STATE_PRIVATE_OPEN_UNIVERSITY",
    name: "State Private Open University"
  },
  { id: "STATE_PRIVATE_UNIVERSITY", name: "State Private University" },
  { id: "STATE_PUBLIC_UNIVERSITY", name: "State Public University" },
  { id: "OTHER", name: "Other" }
];

const MIMETypesMapping = {
  ".apng": "apng",
  ".avif": "avif",
  ".gif": "gif",
  ".jpg": "jpeg",
  ".jpeg": "jpeg",
  ".jfif": "jpeg",
  ".pjpeg": "jpeg",
  ".pjp": "jpeg",
  ".png": "png",
  ".svg": "svg+xml",
  ".webp": "webp",
  ".pdf": "pdf",
  ".mp4": "mp4",
  ".mov": "quicktime",
  ".webm": "webm"
};

export const getMIMETypeOfFile = (fileType) => {
  return MIMETypesMapping[fileType];
};

export const acceptImageTypes =
  ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp";

export const acceptVideoTypes = ".mp4, .mov, .webm";

export const broadCastCategories = [
  { id: "GENERAL", name: "General" },
  { id: "PLACEMENT_DRIVE", name: "Placement Drive" },
  { id: "NEW_COURSE", name: "New Course" },
  { id: "WEBINAR", name: "Webinar" },
  { id: "EVENTS", name: "Events" }
];

export const broadCastCategoriesTypes = {
  GENERAL: "General",
  PLACEMENT_DRIVE: "Placement Drive",
  NEW_COURSE: "New Course",
  WEBINAR: "Webinar",
  EVENTS: "Events"
};

export const broadCastTargetGroup = [
  { id: "STUDENTS", name: "Students" },
  { id: "INSTITUTIONS", name: "Institutions" },
  { id: "EMPLOYERS", name: "Employers" }
];

export const broadCastTargetGroupTypes = {
  STUDENTS: "Students",
  INSTITUTIONS: "Institutions",
  EMPLOYERS: "Employers"
};
