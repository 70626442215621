import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { clearSession, isJwtTokenValid } from "../utils/helpers";
import React, { lazy } from "react";
import { Forbidden } from "./pages/Forbidden/Loadable";

export type ProtectedRouteProps = {
  authenticationPath: string;
  // verificationPath: string;
  roles?: string[];
} & RouteProps;

export default function ProtectedRoute({
  roles,
  authenticationPath,
  // verificationPath,
  ...routeProps
}: ProtectedRouteProps) {
  const location = useLocation();
  const { hasExpired, isVerified, roles: tokenRoles } = isJwtTokenValid();
  if (!hasExpired) {
    if (roles && roles.length > 0) {
      const roleIntersection = roles.filter((value: string) =>
        tokenRoles.includes(value)
      );
      if (roleIntersection.length === 0) {
        const { component, ...rest } = routeProps;
        return <Route component={Forbidden} {...rest} />;
      }
    }
    return <Route {...routeProps} />;
  } else {
    const baseURL = process.env.REACT_APP_WORDPRESS_BASE_URI || "";
    clearSession();
    window.location.replace(baseURL);
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}
