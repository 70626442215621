/**
 * Asynchronously loads the component for CampusIndustryImmersionProgram
 */

 import { lazyLoad } from "utils/loadable";

 export const CampusIndustryImmersionProgram = lazyLoad(
   () => import("./index"),
   (module) => module.CampusIndustryImmersionProgram
 );
 