import { colors } from "../styles/colors";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import React from "react";
// import moment from "moment";

export function getStudentNavbarSelected(location, currentIteration) {
  if (location.pathname === "/student-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (
    location.pathname === "/student-search-jobs-and-internships" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-upskilling" ||
      location.pathname.includes("/student-match-profile")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-mafoi-employability-score" ||
      location.pathname.includes("/student-mafoi-employability-score")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-events" ||
      location.pathname.includes("/student-events")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/student-myapplication" &&
    currentIteration.id === 5
  ) {
    return true;
  }
  // if (
  //   location.pathname === "/student-favourites" &&
  //   currentIteration.id === 5
  // ) {
  //   return true;
  // }
  if (location.pathname === "/student-profile" && currentIteration.id === 6) {
    return true;
  }
  if (
    location.pathname === "/student-resume-templates" &&
    currentIteration.id === 7
  ) {
    return true;
  }
  if (
    location.pathname === "/student-notifications" &&
    currentIteration.id === 8
  ) {
    return true;
  }
  return false;
}

export function getStudentBreadcrumbs(location) {
  if (location.pathname === "/student-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-search-jobs-and-internships") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Search Job/Internship",
        path: "/student-search-jobs-and-internships",
        color: colors.primary
      }
    ];
  }
  // if (location.pathname.includes("/student-job-detail")) {
  //   let jobTitle = "";
  //   if (location && location.state && location.state.title) {
  //     jobTitle = location.state.title;
  //   }
  //   return [
  //     {
  //       id: 1,
  //       name: "Dashboard",
  //       path: "/student-dashboard",
  //       color: "#A4A5A8",
  //     },
  //     {
  //       id: 2,
  //       name: "Search Job/Internship",
  //       path: "/student-search-jobs-and-internships",
  //       color: "#A4A5A8",
  //     },
  //     {
  //       id: 3,
  //       name: jobTitle,
  //       path: "",
  //       color: colors.primary,
  //     },
  //   ];
  // }
  if (location.pathname === "/student-upskilling") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-match-profile")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Match Profile",
        path: "",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-events")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-events",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/student-allcommunity")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-events",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Community Engagement",
        path: "/student-events",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/student-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-myapplication") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Application",
        path: "/student-myapplication",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-favourites") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Favourites",
        path: "/student-favourites",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/student-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/student-notifications",
        color: colors.primary
      }
    ];
  }
  return [];
}

export function getEmployerNavbarSelected(location, currentIteration) {
  if (
    location.pathname === "/employer-dashboard" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (
    (location.pathname === "/employer-search-job" ||
      location.pathname?.includes("/employer-job-view-details/") ||
      location.pathname?.includes("/employer-job-appliedcanditate/") ||
      location.pathname?.includes("/employer-add-job/")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  // if (
  //   (location.pathname === "/employer-internship" ||
  //     location.pathname.includes("/employer-internship")) &&
  //   currentIteration.id === 2
  // ) {
  //   return true;
  // }
  // if (
  //   (location.pathname === "/employer-intern-registration" ||
  //     location.pathname.includes("/employer-intern-registration")) &&
  //   currentIteration.id === 2
  // ) {
  //   return true;
  // }
  if (
    location.pathname === "/employer-applied-candidates" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/employer-college-connect" ||
      location.pathname.includes("/employer-college-list") ||
      location.pathname.includes("/employer-college-connect-students-list")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-search-candidates" &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-notifications" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  return false;
}

export function getEmployerBreadcrumbs(location) {
  if (location.pathname === "/employer-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/employer-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-internship") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Internship",
        path: "/employer-internship",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/employer-internship-details") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Internship",
        path: "/employer-internship",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Internship Details",
        path: "/employer-internship-details",
        color: colors.primary
      }
    ];
  }
  if (location.pathname.includes("/employer-intern-registration")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Intership",
        path: "/employer-internship",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Post Internship",
        path: "/employer-intern-registration",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-applied-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Applied Candidates",
        path: "/employer-applied-candidates",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-college-connect-students-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: "#A4A5A8"
      },
      {
        id: 4,
        name: "Students List",
        path: "/employer-college-connect-students-list",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-search-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-add-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Post Job",
        path: "/employer-add-job",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-job-draft") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Drafts",
        path: "/employer-job-draft",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/employer-job-appliedcanditate") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Jobs / Internships",
        path: "/employer-search-job",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Job Title",
        path: "/employer-job-view-details",
        color: "#A4A5A8"
      },
      {
        id: 4,
        name: "Applied Candidate",
        path: "/employer-job-appliedcanditate",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-search-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Search Candidates",
        path: "/employer-search-candidates",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/employer-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/employer-notifications",
        color: colors.primary
      }
    ];
  }
  return [];
}

export function getCampusNavBarSelected(location, currentIteration) {
  if (location.pathname === "/campus-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (
    (location.pathname === "/campus-students" ||
      location.pathname.includes("/campus-students")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-bulk-update-students" ||
      location.pathname.includes("/campus-bulk-update-students")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-add-edit-student" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-student-activity" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-connect" ||
      location.pathname.includes("/campus-industry-connect")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-faculty-development" ||
      location.pathname.includes("/campus-faculty-development")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-immersion-program" ||
      location.pathname.includes("/campus-industry-immersion-program")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-notifications" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-consulting-project" ||
      location.pathname.includes("/campus-industry-consulting-project")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/campus-service-offerings" &&
    currentIteration.id === 5
  ) {
    return true;
  }

  return false;
}

export function getCampusFLowBreadcrumbs(location) {
  if (location.pathname === "/campus-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "My Profile",
        path: "/campus-profile",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-students") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-bulk-update-students") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Bulk Upload",
        path: "/campus-bulk-update-students",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-add-edit-student") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Add Student",
        path: "/campus-add-edit-student",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-student-activity") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Student Activity",
        path: "/campus-student-activity",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Notification",
        path: "/campus-notifications",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: "#A4A5A8"
      },
      {
        id: 3,
        name: "Posted Jobs",
        path: "/campus-industry-connect/posted-jobs",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-faculty-development") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Faculty Development",
        path: "/campus-faculty-development",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-immersion-program") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Immersion Program",
        path: "/campus-industry-immersion-program",
        color: colors.primary
      }
    ];
  }

  if (location.pathname === "/campus-industry-consulting-project") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Industry Consulting Project",
        path: "/campus-industry-consulting-project",
        color: colors.primary
      }
    ];
  }
  if (location.pathname === "/campus-service-offerings") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: "#A4A5A8"
      },
      {
        id: 2,
        name: "Service Offerings",
        path: "/campus-service-offerings",
        color: colors.primary
      }
    ];
  }
  return [];
}
export const isJwtTokenValid = () => {
  const token = localStorage.getItem("token") || "";
  let hasExpired = true;
  let isVerified = false;
  let roles: string[] = [];
  let lastLogin = null;
  let username = "";
  let profile_completion_status = false;
  let is_assesement_active = false;

  if (Boolean(token)) {
    /**
     * Decodes the JWT token and returns the decoded payload.
     * @param {string} token - The JWT token to be decoded.
     * @returns {any} The decoded payload of the JWT token.
     */
    const decoded: any = jwt_decode(token);
    if (Date.now() < decoded.exp * 1000) {
      hasExpired = false;
      if (Boolean(decoded.verified)) {
        isVerified = true;
      }
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }
      if (Boolean(decoded.profile_completion_status)) {
        profile_completion_status = decoded.profile_completion_status;
      }

      if (Boolean(decoded.lastLogin)) {
        lastLogin = decoded.lastLogin;
      }
      if (Boolean(decoded.username)) {
        username = decoded.username;
      }
      if (Boolean(decoded.is_assesement_active)) {
        is_assesement_active = decoded.is_assesement_active;
      }
    }
  }

  return {
    hasExpired,
    isVerified,
    roles,
    lastLogin,
    username,
    profile_completion_status,
    is_assesement_active
  };
};

// export const [profileComplete , setProfileComplete] =React.useState(true)

/**
 * Clears the session storage by removing the 'token' and 'me' keys in the session storage.
 */
export const clearSession = () => {
  ["token", "me"].forEach((k) => localStorage.removeItem(k));
};

export const isTokenPresent = () => {
  return Boolean(localStorage.getItem("token"));
};

export const getDefaultRoute = () => {
  const { hasExpired, roles } = isJwtTokenValid();
  let me: any = {};
  if (Boolean(localStorage.getItem("me"))) {
    const temp = localStorage.getItem("me");
    me = temp ? JSON.parse(temp) : "";
  }
  if (!hasExpired) {
    if (roles[0] === "college") {
      return "/campus-dashboard";
    }
    // if (roles[0] === "college" && me?.college && me?.college?.status !== 0) {
    //   return "/campus-dashboard";
    // }
    // if (
    //   roles[0] === "college" &&
    //   me?.college &&
    //   (me?.college?.status === 0 || me?.college?.status === 9)
    // ) {
    //   return "/campus-kyc";
    // }
    if (roles[0] === "employer_user" || roles[0] === "employer_user") {
      return "/employer-dashboard";
    }
    // if (
    //   roles[0] === "employer_user" &&
    //   me?.organization &&
    //   me?.organization?.status !== 0
    // ) {
    //   return "/employer-dashboard";
    // }
    // if (
    //   roles[0] === "employer_user" &&
    //   me?.organization &&
    //   (me?.organization?.status === 0 || me?.organization?.status === 9)
    // ) {
    //   return "/employer-kyc";
    // }
    if (roles[0] === "student") {
      return "/student-dashboard";
      // if (profile_completion_status == false) {
      //   return "/student-profile-completion";
      // } else {
      //   return "/student-dashboard";
      // }
    }
    if (roles[0] === "super_admin") {
      return "/admin-dashboard";
    }
  }
  return "/";
};

export function getAdminNavbarSelected(location, currentIteration) {
  if (location.pathname === "/admin-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (location.pathname === "/admin-employers" && currentIteration.id === 1) {
    return true;
  }
  if (
    location.pathname === "/admin-institutions" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (location.pathname === "/admin-students" && currentIteration.id === 3) {
    return true;
  }
  if (
    (location.pathname === "/admin-search-job" ||
      location.pathname?.includes("/admin-job-appliedcanditate/") ||
      location.pathname?.includes("/admin-job-view-details/")) &&
    currentIteration.id === 4
  ) {
    return true;
  }
  return false;
}

// export const getPrettyDate = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("DD MMM YYYY hh:mm A");
// };

// export const getMonthAndYear = (d) => {
//   let dMoment = moment(d);
//   return dMoment.format("MMM YYYY");
// };

export const downloadCSV = (csvContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadExcel = (excelContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:application/vnd.ms-excel;base64," + encodeURI(excelContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadAny = (fileContent, filename, fileType) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:" + fileType + ";base64," + encodeURI(fileContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadBase64ToExcelFile = (base64String, fileName) => {
  const binaryString = window.atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  link.click();
};

export const capitalize = function (word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const getLoggedInUserData = () => {
  let meJSON = localStorage.getItem("me");
  return meJSON ? JSON.parse(meJSON) : null;
};

export const getDateInFormat = (date, format) => {
  return dayjs(date).format(format);
};

export const maskMobile = (mobile: string) => {
  return mobile && mobile.length === 10
    ? mobile.substring(0, 2) + "XXXXX" + mobile.substring(8)
    : mobile;
};

export const getUserInfo = () => {
  const me = localStorage.getItem("me") || "{}";
  // console.log(me)
  const parsedMe = JSON.parse(me);
  const { firstName, lastName, mobile, email, organization, college, id } =
    parsedMe;

  return {
    firstName: firstName || "",
    lastName: lastName || "",
    mobile: mobile || "",
    email: email || "",
    organization: organization?.name || college?.name || "",
    id: id || ""
  };
};

export const formDateFromStringYYYYMMDD = (dateString) => {
  /**
   * Parses a date string in the format "YYYY-MM-DD" and returns a new Date object.
   * @param {string} dateString - The date string to be parsed.
   * @returns {Date} - The parsed date object.
   */
  const dateParts = dateString.split("-");
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  return date;
};

// export const getFromNowDate = (date) => {
//   var relativeTime = require("dayjs/plugin/relativeTime");
//   dayjs.extend(relativeTime);
//   return dayjs(date).fromNow();
// };

export const checkCGPAValidationTranscript = (semester_details) => {
  let flag = 0;
  if (semester_details && semester_details.length > 0) {
    for (let i = 0; i < semester_details.length; i += 1) {
      if (
        semester_details[i] &&
        semester_details[i]?.subjects &&
        semester_details[i]?.subjects.length > 0
      ) {
        for (let j = 0; j < semester_details[i]?.subjects.length; j += 1) {
          if (
            semester_details &&
            semester_details[i] &&
            semester_details[i]?.subjects &&
            semester_details[i]?.subjects[j] &&
            semester_details[i]?.subjects[j]?.cgpa &&
            (parseFloat(semester_details[i]?.subjects[j]?.cgpa) < 0 ||
              parseFloat(semester_details[i]?.subjects[j]?.cgpa) > 100)
          ) {
            flag = 1;
            break;
          }
        }
      }
    }
  }
  return flag === 1;
};
