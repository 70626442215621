import React, { useMemo } from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ServerError
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { useHistory } from "react-router-dom";
import getStore from "store/zustandStore";

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return { authorization: `JWT ${token}` };
  }
  return {};
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getToken()
    }
  }));

  return forward(operation);
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URI
}) as unknown as ApolloLink;

const createErrorLink = (history, setLoading) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.error(
        `[Server error]: A server error (${networkError}) occurred.`
      );
      // const response = (networkError as any).response;
      // if (response && response.status >= 500) {
      //   console.error(
      //     `[Server error]: A server error (${response.status}) occurred.`
      //   );
      //   setLoading(false);
      //   history.push("/under-maintenance");
      // }

      // Check for network connectivity issues
      if (!window.navigator.onLine) {
        console.error(`[Network error]: No internet connection.`);
        setLoading(false);
        // history.push("/no-internet");
      }
    }
  });

const AppApolloProvider = ({ children }) => {
  const history = useHistory();
  const setLoading = getStore((state: any) => state.setLoading);

  // Memoize the Apollo Client instance to ensure it is only created once
  const client = useMemo(() => {
    const errorLink = createErrorLink(history, setLoading);

    return new ApolloClient({
      link: ApolloLink.from([authMiddleware, errorLink, uploadLink]),
      cache: new InMemoryCache()
    });
  }, [history]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppApolloProvider;
