/**
 * Asynchronously loads the component for EmployerSearchCandidates
 */

import { lazyLoad } from "utils/loadable";

export const EmployerSearchCandidates = lazyLoad(
  () => import("./index"),
  (module) => module.EmployerSearchCandidates
);
