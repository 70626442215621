/**
 * Asynchronously loads the component for EmployerCollegeConnect
 */

 import { lazyLoad } from "utils/loadable";

 export const EmployerCollegeConnect = lazyLoad(
   () => import("./index"),
   (module) => module.EmployerCollegeConnect
 );
 