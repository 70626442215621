/**
 * Asynchronously loads the component for EmployerKYC
 */

import { lazyLoad } from "utils/loadable";

export const EmployerKYC = lazyLoad(
  () => import("./index"),
  (module) => module.EmployerKYC
);
